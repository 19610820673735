(window.theme = window.theme || {}),
    (window.theme = window.theme || {}),
    (theme.Sections = function () {
        (this.constructors = {}),
            (this.instances = []),
            $(document)
                .on("shopify:section:load", this._onSectionLoad.bind(this))
                .on("shopify:section:unload", this._onSectionUnload.bind(this))
                .on("shopify:section:select", this._onSelect.bind(this))
                .on("shopify:section:deselect", this._onDeselect.bind(this))
                .on("shopify:block:select", this._onBlockSelect.bind(this))
                .on("shopify:block:deselect", this._onBlockDeselect.bind(this));
    }),
    (theme.Sections.prototype = Object.assign({}, theme.Sections.prototype, {
        _createInstance: function (e, t) {
            var i = $(e),
                s = i.attr("data-section-id"),
                a = i.attr("data-section-type");
            if (void 0 !== (t = t || this.constructors[a])) {
                var r = Object.assign(new t(e), { id: s, type: a, container: e });
                this.instances.push(r);
            }
        },
        _onSectionLoad: function (e) {
            var t = $("[data-section-id]", e.target)[0];
            t && this._createInstance(t);
        },
        _onSectionUnload: function (e) {
            this.instances = this.instances.filter(function (t) {
                var i = t.id === e.detail.sectionId;
                return i && "function" == typeof t.onUnload && t.onUnload(e), !i;
            });
        },
        _onSelect: function (e) {
            var t = this.instances.find(function (t) {
                return t.id === e.detail.sectionId;
            });
            void 0 !== t && "function" == typeof t.onSelect && t.onSelect(e);
        },
        _onDeselect: function (e) {
            var t = this.instances.find(function (t) {
                return t.id === e.detail.sectionId;
            });
            void 0 !== t && "function" == typeof t.onDeselect && t.onDeselect(e);
        },
        _onBlockSelect: function (e) {
            var t = this.instances.find(function (t) {
                return t.id === e.detail.sectionId;
            });
            void 0 !== t && "function" == typeof t.onBlockSelect && t.onBlockSelect(e);
        },
        _onBlockDeselect: function (e) {
            var t = this.instances.find(function (t) {
                return t.id === e.detail.sectionId;
            });
            void 0 !== t && "function" == typeof t.onBlockDeselect && t.onBlockDeselect(e);
        },
        register: function (e, t) {
            (this.constructors[e] = t),
                $("[data-section-type=" + e + "]").each(
                    function (e, i) {
                        this._createInstance(i, t);
                    }.bind(this)
                );
        },
    })),
    (window.slate = window.slate || {}),
    (slate.utils = {
        getParameterByName: function (e, t) {
            t || (t = window.location.href), (e = e.replace(/[[\]]/g, "\\$&"));
            var i = new RegExp("[?&]" + e + "(=([^&#]*)|&|#|$)").exec(t);
            return i ? (i[2] ? decodeURIComponent(i[2].replace(/\+/g, " ")) : "") : null;
        },
        resizeSelects: function (e) {
            e.each(function () {
                var e = $(this),
                    t = e.find("option:selected").text(),
                    i = $("<span>").html(t);
                i.appendTo("body");
                var s = i.width();
                i.remove(), e.width(s + 10);
            });
        },
        keyboardKeys: { TAB: 9, ENTER: 13, ESCAPE: 27, LEFTARROW: 37, RIGHTARROW: 39 },
    }),
    (window.slate = window.slate || {}),
    (slate.rte = {
        wrapTable: function (e) {
            e.$tables.wrap('<div class="' + e.tableWrapperClass + '"></div>');
        },
        wrapIframe: function (e) {
            e.$iframes.each(function () {
                $(this).wrap('<div class="' + e.iframeWrapperClass + '"></div>'), (this.src = this.src);
            });
        },
    }),
    (window.slate = window.slate || {}),
    (slate.a11y = {
        pageLinkFocus: function (e) {
            var t = "js-focus-hidden";
            e.first()
                .attr("tabIndex", "-1")
                .focus()
                .addClass(t)
                .one("blur", function () {
                    e.first().removeClass(t).removeAttr("tabindex");
                });
        },
        focusHash: function () {
            var e = window.location.hash;
            e && document.getElementById(e.slice(1)) && this.pageLinkFocus($(e));
        },
        bindInPageLinks: function () {
            $("a[href*=#]").on(
                "click",
                function (e) {
                    this.pageLinkFocus($(e.currentTarget.hash));
                }.bind(this)
            );
        },
        trapFocus: function (e) {
            e.container && (e.$container = $(e.container));
            var t = { focusin: e.namespace ? "focusin." + e.namespace : "focusin", focusout: e.namespace ? "focusout." + e.namespace : "focusout", keydown: e.namespace ? "keydown." + e.namespace : "keydown.handleFocus" },
                i = e.$container.find($('button, [href], input, select, textarea, [tabindex]:not([tabindex^="-"])').filter(":visible")),
                s = i[0],
                a = i[i.length - 1];
            e.elementToFocus && (e.$elementToFocus = $(e.elementToFocus)),
                e.$elementToFocus || (e.$elementToFocus = e.$container),
                e.$container.attr("tabindex", "-1"),
                e.$elementToFocus.focus(),
                $(document).off("focusin"),
                $(document).on(t.focusout, function () {
                    $(document).off(t.keydown);
                }),
                $(document).on(t.focusin, function (e) {
                    (e.target !== a && e.target !== s) ||
                        $(document).on(t.keydown, function (e) {
                            !(function (e) {
                                e.keyCode === slate.utils.keyboardKeys.TAB && (e.target !== a || e.shiftKey || (e.preventDefault(), s.focus()), e.target === s && e.shiftKey && (e.preventDefault(), a.focus()));
                            })(e);
                        });
                });
        },
        removeTrapFocus: function (e) {
            e.container && (e.$container = $(e.container));
            var t = e.namespace ? "focusin." + e.namespace : "focusin";
            e.$container && e.$container.length && e.$container.removeAttr("tabindex"), $(document).off(t);
        },
        accessibleLinks: function (e) {
            var t = document.querySelector("body"),
                i = { newWindow: "a11y-new-window-message", external: "a11y-external-message", newWindowExternal: "a11y-new-window-external-message" };
            (void 0 !== e.$links && e.$links.jquery) || (e.$links = $("a[href]:not([aria-describedby])")),
                $.each(e.$links, function () {
                    var e = $(this),
                        t = e.attr("target"),
                        s = e.attr("rel"),
                        a = (function (e) {
                            var t = window.location.hostname;
                            return e[0].hostname !== t;
                        })(e),
                        r = "_blank" === t;
                    a && e.attr("aria-describedby", i.external),
                        r &&
                            ((void 0 !== s && -1 !== s.indexOf("noopener")) ||
                                e.attr("rel", function (e, t) {
                                    return (void 0 === t ? "" : t + " ") + "noopener";
                                }),
                            e.attr("aria-describedby", i.newWindow)),
                        a && r && e.attr("aria-describedby", i.newWindowExternal);
                }),
                (function (e) {
                    "object" != typeof e && (e = {});
                    var s = $.extend({ newWindow: "Opens in a new window.", external: "Opens external website.", newWindowExternal: "Opens external website in a new window." }, e),
                        a = document.createElement("ul"),
                        r = "";
                    for (var n in s) r += "<li id=" + i[n] + ">" + s[n] + "</li>";
                    a.setAttribute("hidden", !0), (a.innerHTML = r), t.appendChild(a);
                })(e.messages);
        },
    }),
    (theme.Images = {
        preload: function (e, t) {
            "string" == typeof e && (e = [e]);
            for (var i = 0; i < e.length; i++) {
                var s = e[i];
                this.loadImage(this.getSizedImageUrl(s, t));
            }
        },
        loadImage: function (e) {
            new Image().src = e;
        },
        switchImage: function (e, t, i) {
            var s = this.imageSize(t.src),
                a = this.getSizedImageUrl(e.src, s);
            i ? i(a, e, t) : (t.src = a);
        },
        imageSize: function (e) {
            var t = e.match(/.+_((?:pico|icon|thumb|small|compact|medium|large|grande)|\d{1,4}x\d{0,4}|x\d{1,4})[_\\.@]/);
            return null !== t ? (void 0 !== t[2] ? t[1] + t[2] : t[1]) : null;
        },
        getSizedImageUrl: function (e, t) {
            if (null === t) return e;
            if ("master" === t) return this.removeProtocol(e);
            var i = e.match(/\.(jpg|jpeg|gif|png|bmp|bitmap|tiff|tif)(\?v=\d+)?$/i);
            if (null !== i) {
                var s = e.split(i[0]),
                    a = i[0];
                return this.removeProtocol(s[0] + "_" + t + a);
            }
            return null;
        },
        removeProtocol: function (e) {
            return e.replace(/http(s)?:/, "");
        },
    }),
    (theme.Currency = {
        formatMoney: function (e, t) {
            "string" == typeof e && (e = e.replace(".", ""));
            var i = "",
                s = /\{\{\s*(\w+)\s*\}\}/,
                a = t || "${{amount}}";
            function r(e, t, i, s) {
                if (((i = i || ","), (s = s || "."), isNaN(e) || null === e)) return 0;
                var a = (e = (e / 100).toFixed(t)).split(".");
                return a[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1" + i) + (a[1] ? s + a[1] : "");
            }
            switch (a.match(s)[1]) {
                case "amount":
                    i = r(e, 2);
                    break;
                case "amount_no_decimals":
                    i = r(e, 0);
                    break;
                case "amount_with_comma_separator":
                    i = r(e, 2, ".", ",");
                    break;
                case "amount_no_decimals_with_comma_separator":
                    i = r(e, 0, ".", ",");
                    break;
                case "amount_no_decimals_with_space_separator":
                    i = r(e, 0, " ");
                    break;
                case "amount_with_apostrophe_separator":
                    i = r(e, 2, "'");
            }
            return a.replace(s, i);
        },
    }),
    (slate.Variants = (function () {
        function e(e) {
            (this.$container = e.$container),
                (this.product = e.product),
                (this.singleOptionSelector = e.singleOptionSelector),
                (this.originalSelectorId = e.originalSelectorId),
                (this.enableHistoryState = e.enableHistoryState),
                (this.currentVariant = this._getVariantFromOptions()),
                $(this.singleOptionSelector, this.$container).on("change", this._onSelectChange.bind(this));
        }
        return (
            (e.prototype = Object.assign({}, e.prototype, {
                _getCurrentOptions: function () {
                    return $(this.singleOptionSelector, this.$container)
                        .map(function (e, t) {
                            var i = $(t),
                                s = i.attr("type"),
                                a = {};
                            return "radio" === s || "checkbox" === s ? !!i[0].checked && ((a.value = i.val()), (a.index = i.data("index")), a) : ((a.value = i.val()), (a.index = i.data("index")), a);
                        })
                        .get();
                },
                _getVariantFromOptions: function () {
                    var e = this._getCurrentOptions();
                    return this.product.variants.find(function (t) {
                        return e.every(function (e) {
                            return t[e.index] === e.value;
                        });
                    });
                },
                _onSelectChange: function () {
                    var e = this._getVariantFromOptions();
                    this.$container.trigger({ type: "variantChange", variant: e }),
                        e && (this._updateMasterSelect(e), this._updateImages(e), this._updatePrice(e), this._updateSKU(e), (this.currentVariant = e), this.enableHistoryState && this._updateHistoryState(e));
                },
                _updateImages: function (e) {
                    var t = e.featured_image || {},
                        i = this.currentVariant.featured_image || {};
                    e.featured_image && t.src !== i.src && this.$container.trigger({ type: "variantImageChange", variant: e });
                },
                _updatePrice: function (e) {
                    (e.price === this.currentVariant.price && e.compare_at_price === this.currentVariant.compare_at_price) || this.$container.trigger({ type: "variantPriceChange", variant: e });
                },
                _updateSKU: function (e) {
                    e.sku !== this.currentVariant.sku && this.$container.trigger({ type: "variantSKUChange", variant: e });
                },
                _updateHistoryState: function (e) {
                    if (history.replaceState && e) {
                        var t = window.location.protocol + "//" + window.location.host + window.location.pathname + "?variant=" + e.id;
                        window.history.replaceState({ path: t }, "", t);
                    }
                },
                _updateMasterSelect: function (e) {
                    $(this.originalSelectorId, this.$container).val(e.id);
                },
            })),
            e
        );
    })()),
    (this.Shopify = this.Shopify || {}),
    (this.Shopify.theme = this.Shopify.theme || {}),
    (this.Shopify.theme.PredictiveSearch = (function () {
        "use strict";
        function e(e) {
            var t = Error.call(this);
            return (t.name = "JSON parse error"), (t.message = "JSON syntax error"), (t.status = e), t;
        }
        function t(e) {
            (this._store = {}), (this._keys = []), e && e.bucketSize ? (this.bucketSize = e.bucketSize) : (this.bucketSize = 20);
        }
        function i() {
            this.events = {};
        }
        function s(e) {
            (this.eventName = e), (this.callbacks = []);
        }
        (t.prototype.set = function (e, t) {
            if (this.count() >= this.bucketSize) {
                var i = this._keys.splice(0, 1);
                this.delete(i);
            }
            return this._keys.push(e), (this._store[e] = t), this._store;
        }),
            (t.prototype.get = function (e) {
                return this._store[e];
            }),
            (t.prototype.has = function (e) {
                return Boolean(this._store[e]);
            }),
            (t.prototype.count = function () {
                return Object.keys(this._store).length;
            }),
            (t.prototype.delete = function (e) {
                var t = Boolean(this._store[e]);
                return delete this._store[e], t && !this._store[e];
            }),
            (i.prototype.on = function (e, t) {
                var i = this.events[e];
                i || ((i = new s(e)), (this.events[e] = i)), i.registerCallback(t);
            }),
            (i.prototype.off = function (e, t) {
                var i = this.events[e];
                i && i.callbacks.indexOf(t) > -1 && (i.unregisterCallback(t), 0 === i.callbacks.length && delete this.events[e]);
            }),
            (i.prototype.dispatch = function (e, t) {
                var i = this.events[e];
                i && i.fire(t);
            }),
            (s.prototype.registerCallback = function (e) {
                this.callbacks.push(e);
            }),
            (s.prototype.unregisterCallback = function (e) {
                var t = this.callbacks.indexOf(e);
                t > -1 && this.callbacks.splice(t, 1);
            }),
            (s.prototype.fire = function (e) {
                this.callbacks.slice(0).forEach(function (t) {
                    t(e);
                });
            });
        var a,
            r,
            n =
                ((a = function (t, i, s, a) {
                    var r = new XMLHttpRequest();
                    (r.onreadystatechange = function () {
                        if (r.readyState === XMLHttpRequest.DONE) {
                            var t = r.getResponseHeader("Content-Type");
                            if (r.status >= 500)
                                a(
                                    new (function () {
                                        var e = Error.call(this);
                                        return (e.name = "Server error"), (e.message = "Something went wrong on the server"), (e.status = 500), e;
                                    })()
                                );
                            else if (404 !== r.status)
                                if ("string" == typeof t && null !== t.toLowerCase().match("application/json"))
                                    if (417 !== r.status)
                                        if (422 !== r.status)
                                            if (429 !== r.status)
                                                if (200 !== r.status)
                                                    try {
                                                        var n = JSON.parse(r.responseText);
                                                        a(
                                                            new (function () {
                                                                var e = Error.call(this);
                                                                return (e.name = "Server error"), (e.message = "Something went wrong on the server"), (e.status = 500), e;
                                                            })(r.status, n.message, n.description)
                                                        );
                                                    } catch (t) {
                                                        a(new e(r.status));
                                                    }
                                                else
                                                    try {
                                                        var o = JSON.parse(r.responseText);
                                                        (o.query = i), s(o);
                                                    } catch (t) {
                                                        a(new e(r.status));
                                                    }
                                            else
                                                try {
                                                    var c = JSON.parse(r.responseText);
                                                    a(
                                                        new (function (e, t, i, s) {
                                                            var a = Error.call(this);
                                                            return (a.name = t), (a.message = i), (a.status = e), (a.retryAfter = s), a;
                                                        })(r.status, c.message, c.description, r.getResponseHeader("Retry-After"))
                                                    );
                                                } catch (t) {
                                                    a(new e(r.status));
                                                }
                                        else
                                            try {
                                                var l = JSON.parse(r.responseText);
                                                a(
                                                    new (function (e, t, i) {
                                                        var s = Error.call(this);
                                                        return (s.name = t), (s.message = i), (s.status = e), s;
                                                    })(r.status, l.message, l.description)
                                                );
                                            } catch (t) {
                                                a(new e(r.status));
                                            }
                                    else
                                        try {
                                            var d = JSON.parse(r.responseText);
                                            a(
                                                new (function (e, t, i) {
                                                    var s = Error.call(this);
                                                    return (s.name = t), (s.message = i), (s.status = e), s;
                                                })(r.status, d.message, d.description)
                                            );
                                        } catch (t) {
                                            a(new e(r.status));
                                        }
                                else
                                    a(
                                        new (function (e) {
                                            var t = Error.call(this);
                                            return (t.name = "Content-Type error"), (t.message = "Content-Type was not provided or is of wrong type"), (t.status = e), t;
                                        })(r.status)
                                    );
                            else
                                a(
                                    new (function (e) {
                                        var t = Error.call(this);
                                        return (t.name = "Not found"), (t.message = "Not found"), (t.status = e), t;
                                    })(r.status)
                                );
                        }
                    }),
                        r.open("get", "/search/suggest.json?q=" + encodeURIComponent(i) + "&" + t),
                        r.setRequestHeader("Content-Type", "application/json"),
                        r.send();
                }),
                (r = null),
                function () {
                    var e = this,
                        t = arguments;
                    clearTimeout(r),
                        (r = setTimeout(function () {
                            (r = null), a.apply(e, t);
                        }, 10));
                });
        function o(e) {
            if (!e) throw new TypeError("No config object was specified");
            (this._retryAfter = null),
                (this._currentQuery = null),
                (this.dispatcher = new i()),
                (this.cache = new t({ bucketSize: 40 })),
                (this.configParams = (function e(t, i) {
                    var s = "";
                    return (
                        (i = i || null),
                        Object.keys(t).forEach(function (a) {
                            var r = a + "=";
                            switch (
                                (i && (r = i + "[" + a + "]"),
                                (function (e) {
                                    return Object.prototype.toString.call(e).slice(8, -1).toLowerCase();
                                })(t[a]))
                            ) {
                                case "object":
                                    s += e(t[a], i ? r : a);
                                    break;
                                case "array":
                                    s += r + "=" + t[a].join(",") + "&";
                                    break;
                                default:
                                    i && (r += "="), (s += r + encodeURIComponent(t[a]) + "&");
                            }
                        }),
                        s
                    );
                })(e));
        }
        function c(e) {
            return "string" != typeof e ? null : e.trim().replace(" ", "-").toLowerCase();
        }
        return (
            (o.TYPES = { PRODUCT: "product", PAGE: "page", ARTICLE: "article" }),
            (o.FIELDS = { AUTHOR: "author", BODY: "body", PRODUCT_TYPE: "product_type", TAG: "tag", TITLE: "title", VARIANTS_BARCODE: "variants.barcode", VARIANTS_SKU: "variants.sku", VARIANTS_TITLE: "variants.title", VENDOR: "vendor" }),
            (o.UNAVAILABLE_PRODUCTS = { SHOW: "show", HIDE: "hide", LAST: "last" }),
            (o.prototype.query = function (e) {
                try {
                    !(function (e) {
                        var t;
                        if (null == e) throw (((t = new TypeError("'query' is missing")).type = "argument"), t);
                        if ("string" != typeof e) throw (((t = new TypeError("'query' is not a string")).type = "argument"), t);
                    })(e);
                } catch (e) {
                    return void this.dispatcher.dispatch("error", e);
                }
                if ("" === e) return this;
                this._currentQuery = c(e);
                var t = this.cache.get(this._currentQuery);
                return t
                    ? (this.dispatcher.dispatch("success", t), this)
                    : (n(
                          this.configParams,
                          e,
                          function (e) {
                              this.cache.set(c(e.query), e), c(e.query) === this._currentQuery && ((this._retryAfter = null), this.dispatcher.dispatch("success", e));
                          }.bind(this),
                          function (e) {
                              e.retryAfter && (this._retryAfter = e.retryAfter), this.dispatcher.dispatch("error", e);
                          }.bind(this)
                      ),
                      this);
            }),
            (o.prototype.on = function (e, t) {
                return this.dispatcher.on(e, t), this;
            }),
            (o.prototype.off = function (e, t) {
                return this.dispatcher.off(e, t), this;
            }),
            o
        );
    })()),
    (this.Shopify = this.Shopify || {}),
    (this.Shopify.theme = this.Shopify.theme || {}),
    (this.Shopify.theme.PredictiveSearchComponent = (function (e) {
        "use strict";
        var t = {
            resources: {
                type: [(e = e && e.hasOwnProperty("default") ? e.default : e).TYPES.PRODUCT],
                options: { unavailable_products: e.UNAVAILABLE_PRODUCTS.LAST, fields: [e.FIELDS.TITLE, e.FIELDS.VENDOR, e.FIELDS.PRODUCT_TYPE, e.FIELDS.VARIANTS_TITLE] },
            },
        };
        function i(i) {
            if (
                !(
                    i &&
                    i.selectors &&
                    i.selectors.input &&
                    a(i.selectors.input) &&
                    i.selectors.result &&
                    a(i.selectors.result) &&
                    i.resultTemplateFct &&
                    n(i.resultTemplateFct) &&
                    i.numberOfResultsTemplateFct &&
                    n(i.numberOfResultsTemplateFct) &&
                    i.loadingResultsMessageTemplateFct &&
                    n(i.loadingResultsMessageTemplateFct)
                )
            ) {
                var s = new TypeError("PredictiveSearchComponent config is not valid");
                throw ((s.type = "argument"), s);
            }
            var r, o;
            (this.nodes = ((r = i.selectors), { input: document.querySelector(r.input), reset: document.querySelector(r.reset), result: document.querySelector(r.result) })),
                (function (e) {
                    return !!(e && e.input && e.result && "INPUT" === e.input.tagName);
                })(this.nodes)
                    ? ((this._searchKeyword = ""),
                      (this.resultTemplateFct = i.resultTemplateFct),
                      (this.numberOfResultsTemplateFct = i.numberOfResultsTemplateFct),
                      (this.loadingResultsMessageTemplateFct = i.loadingResultsMessageTemplateFct),
                      (this.numberOfResults = i.numberOfResults || 4),
                      (this.classes = {
                          visibleVariant: i.visibleVariant ? i.visibleVariant : "predictive-search-wrapper--visible",
                          itemSelected: i.itemSelectedClass ? i.itemSelectedClass : "predictive-search-item--selected",
                          clearButtonVisible: i.clearButtonVisibleClass ? i.clearButtonVisibleClass : "predictive-search__clear-button--visible",
                      }),
                      (this.selectors = { searchResult: i.searchResult ? i.searchResult : "[data-search-result]" }),
                      (this.callbacks = (function (e) {
                          return {
                              onBodyMousedown: e.onBodyMousedown,
                              onBeforeOpen: e.onBeforeOpen,
                              onOpen: e.onOpen,
                              onBeforeClose: e.onBeforeClose,
                              onClose: e.onClose,
                              onInputFocus: e.onInputFocus,
                              onInputKeyup: e.onInputKeyup,
                              onInputBlur: e.onInputBlur,
                              onInputReset: e.onInputReset,
                              onBeforeDestroy: e.onBeforeDestroy,
                              onDestroy: e.onDestroy,
                          };
                      })(i)),
                      (o = this.nodes.input).setAttribute("autocorrect", "off"),
                      o.setAttribute("autocomplete", "off"),
                      o.setAttribute("autocapitalize", "off"),
                      o.setAttribute("spellcheck", "false"),
                      this._addInputEventListeners(),
                      this._addBodyEventListener(),
                      this._addAccessibilityAnnouncer(),
                      this._toggleClearButtonVisibility(),
                      (this.predictiveSearch = new e(i.PredictiveSearchAPIConfig ? i.PredictiveSearchAPIConfig : t)),
                      this.predictiveSearch.on("success", this._handlePredictiveSearchSuccess.bind(this)),
                      this.predictiveSearch.on("error", this._handlePredictiveSearchError.bind(this)))
                    : console.warn("Could not find valid nodes");
        }
        function s(e) {
            return Object.prototype.toString.call(e);
        }
        function a(e) {
            return "[object String]" === s(e);
        }
        function r(e) {
            return "[object Boolean]" === s(e);
        }
        function n(e) {
            return "[object Function]" === s(e);
        }
        return (
            (i.prototype.isResultVisible = !1),
            (i.prototype.results = {}),
            (i.prototype._latencyTimer = null),
            (i.prototype._resultNodeClicked = !1),
            (i.prototype._addInputEventListeners = function () {
                var e = this.nodes.input,
                    t = this.nodes.reset;
                e &&
                    ((this._handleInputFocus = this._handleInputFocus.bind(this)),
                    (this._handleInputBlur = this._handleInputBlur.bind(this)),
                    (this._handleInputKeyup = this._handleInputKeyup.bind(this)),
                    (this._handleInputKeydown = this._handleInputKeydown.bind(this)),
                    e.addEventListener("focus", this._handleInputFocus),
                    e.addEventListener("blur", this._handleInputBlur),
                    e.addEventListener("keyup", this._handleInputKeyup),
                    e.addEventListener("keydown", this._handleInputKeydown),
                    t && ((this._handleInputReset = this._handleInputReset.bind(this)), t.addEventListener("click", this._handleInputReset)));
            }),
            (i.prototype._removeInputEventListeners = function () {
                var e = this.nodes.input;
                e.removeEventListener("focus", this._handleInputFocus),
                    e.removeEventListener("blur", this._handleInputBlur),
                    e.removeEventListener("keyup", this._handleInputKeyup),
                    e.removeEventListener("keydown", this._handleInputKeydown);
            }),
            (i.prototype._addBodyEventListener = function () {
                (this._handleBodyMousedown = this._handleBodyMousedown.bind(this)), document.querySelector("body").addEventListener("mousedown", this._handleBodyMousedown);
            }),
            (i.prototype._removeBodyEventListener = function () {
                document.querySelector("body").removeEventListener("mousedown", this._handleBodyMousedown);
            }),
            (i.prototype._removeClearButtonEventListener = function () {
                var e = this.nodes.reset;
                e && e.removeEventListener("click", this._handleInputReset);
            }),
            (i.prototype._handleBodyMousedown = function (e) {
                if (this.isResultVisible && null !== this.nodes)
                    if (e.target.isEqualNode(this.nodes.input) || this.nodes.input.contains(e.target) || e.target.isEqualNode(this.nodes.result) || this.nodes.result.contains(e.target)) this._resultNodeClicked = !0;
                    else if (n(this.callbacks.onBodyMousedown)) {
                        var t = this.callbacks.onBodyMousedown(this.nodes);
                        r(t) && t && this.close();
                    } else this.close();
            }),
            (i.prototype._handleInputFocus = function (e) {
                if (n(this.callbacks.onInputFocus)) {
                    var t = this.callbacks.onInputFocus(this.nodes);
                    if (r(t) && !t) return !1;
                }
                return e.target.value.length > 0 && this._search(), !0;
            }),
            (i.prototype._handleInputBlur = function () {
                return (
                    setTimeout(
                        function () {
                            if (n(this.callbacks.onInputBlur)) {
                                var e = this.callbacks.onInputBlur(this.nodes);
                                if (r(e) && !e) return !1;
                            }
                            return !document.activeElement.isEqualNode(this.nodes.reset) && (this._resultNodeClicked ? ((this._resultNodeClicked = !1), !1) : void this.close());
                        }.bind(this)
                    ),
                    !0
                );
            }),
            (i.prototype._addAccessibilityAnnouncer = function () {
                (this._accessibilityAnnouncerDiv = window.document.createElement("div")),
                    this._accessibilityAnnouncerDiv.setAttribute("style", "position: absolute !important; overflow: hidden; clip: rect(0 0 0 0); height: 1px; width: 1px; margin: -1px; padding: 0; border: 0;"),
                    this._accessibilityAnnouncerDiv.setAttribute("data-search-announcer", ""),
                    this._accessibilityAnnouncerDiv.setAttribute("aria-live", "polite"),
                    this._accessibilityAnnouncerDiv.setAttribute("aria-atomic", "true"),
                    this.nodes.result.parentElement.appendChild(this._accessibilityAnnouncerDiv);
            }),
            (i.prototype._removeAccessibilityAnnouncer = function () {
                this.nodes.result.parentElement.removeChild(this._accessibilityAnnouncerDiv);
            }),
            (i.prototype._updateAccessibilityAttributesAfterSelectingElement = function (e, t) {
                this.nodes.input.setAttribute("aria-activedescendant", t.id), e && e.removeAttribute("aria-selected"), t.setAttribute("aria-selected", !0);
            }),
            (i.prototype._clearAriaActiveDescendant = function () {
                this.nodes.input.setAttribute("aria-activedescendant", "");
            }),
            (i.prototype._announceNumberOfResultsFound = function (e) {
                var t = this._accessibilityAnnouncerDiv.innerHTML,
                    i = this.numberOfResultsTemplateFct(e);
                t === i && (i += "&nbsp;"), (this._accessibilityAnnouncerDiv.innerHTML = i);
            }),
            (i.prototype._announceLoadingState = function () {
                this._accessibilityAnnouncerDiv.innerHTML = this.loadingResultsMessageTemplateFct();
            }),
            (i.prototype._handleInputKeyup = function (e) {
                if (n(this.callbacks.onInputKeyup)) {
                    var t = this.callbacks.onInputKeyup(this.nodes);
                    if (r(t) && !t) return !1;
                }
                if ((this._toggleClearButtonVisibility(), this.isResultVisible && null !== this.nodes)) {
                    if (38 === e.keyCode) return this._navigateOption(e, "UP"), !0;
                    if (40 === e.keyCode) return this._navigateOption(e, "DOWN"), !0;
                    if (13 === e.keyCode) return this._selectOption(), !0;
                    27 === e.keyCode && this.close();
                }
                return e.target.value.length <= 0 ? (this.close(), this._setKeyword("")) : e.target.value.length > 0 && this._search(), !0;
            }),
            (i.prototype._handleInputKeydown = function (e) {
                13 === e.keyCode && null !== this._getSelectedOption() && e.preventDefault(), (38 !== e.keyCode && 40 !== e.keyCode) || e.preventDefault();
            }),
            (i.prototype._handleInputReset = function (e) {
                if ((e.preventDefault(), n(this.callbacks.onInputReset))) {
                    var t = this.callbacks.onInputReset(this.nodes);
                    if (r(t) && !t) return !1;
                }
                return (this.nodes.input.value = ""), this.nodes.input.focus(), this._toggleClearButtonVisibility(), this.close(), !0;
            }),
            (i.prototype._navigateOption = function (e, t) {
                var i = this._getSelectedOption();
                if (i)
                    if ("DOWN" === t) {
                        var s = i.nextElementSibling;
                        s && (i.classList.remove(this.classes.itemSelected), s.classList.add(this.classes.itemSelected), this._updateAccessibilityAttributesAfterSelectingElement(i, s));
                    } else {
                        var a = i.previousElementSibling;
                        a && (i.classList.remove(this.classes.itemSelected), a.classList.add(this.classes.itemSelected), this._updateAccessibilityAttributesAfterSelectingElement(i, a));
                    }
                else {
                    var r = this.nodes.result.querySelector(this.selectors.searchResult);
                    r.classList.add(this.classes.itemSelected), this._updateAccessibilityAttributesAfterSelectingElement(null, r);
                }
            }),
            (i.prototype._getSelectedOption = function () {
                return this.nodes.result.querySelector("." + this.classes.itemSelected);
            }),
            (i.prototype._selectOption = function () {
                var e = this._getSelectedOption();
                e && e.querySelector("a, button").click();
            }),
            (i.prototype._search = function () {
                var e = this.nodes.input.value;
                this._searchKeyword !== e &&
                    (clearTimeout(this._latencyTimer),
                    (this._latencyTimer = setTimeout(
                        function () {
                            (this.results.isLoading = !0), this._announceLoadingState(), this.nodes.result.classList.add(this.classes.visibleVariant), (this.nodes.result.innerHTML = this.resultTemplateFct(this.results));
                        }.bind(this),
                        500
                    )),
                    this.predictiveSearch.query(e),
                    this._setKeyword(e));
            }),
            (i.prototype._handlePredictiveSearchSuccess = function (e) {
                clearTimeout(this._latencyTimer),
                    (this.results = e.resources.results),
                    (this.results.isLoading = !1),
                    (this.results.products = this.results.products.slice(0, this.numberOfResults)),
                    (this.results.canLoadMore = this.numberOfResults <= this.results.products.length),
                    (this.results.searchQuery = this.nodes.input.value),
                    this.results.products.length > 0 || this.results.searchQuery
                        ? ((this.nodes.result.innerHTML = this.resultTemplateFct(this.results)), this._announceNumberOfResultsFound(this.results), this.open())
                        : ((this.nodes.result.innerHTML = ""), this._closeOnNoResults());
            }),
            (i.prototype._handlePredictiveSearchError = function () {
                clearTimeout(this._latencyTimer), (this.nodes.result.innerHTML = ""), this._closeOnNoResults();
            }),
            (i.prototype._closeOnNoResults = function () {
                this.nodes && this.nodes.result.classList.remove(this.classes.visibleVariant), (this.isResultVisible = !1);
            }),
            (i.prototype._setKeyword = function (e) {
                this._searchKeyword = e;
            }),
            (i.prototype._toggleClearButtonVisibility = function () {
                this.nodes.reset && (this.nodes.input.value.length > 0 ? this.nodes.reset.classList.add(this.classes.clearButtonVisible) : this.nodes.reset.classList.remove(this.classes.clearButtonVisible));
            }),
            (i.prototype.open = function () {
                if (!this.isResultVisible) {
                    if (n(this.callbacks.onBeforeOpen)) {
                        var e = this.callbacks.onBeforeOpen(this.nodes);
                        if (r(e) && !e) return !1;
                    }
                    return this.nodes.result.classList.add(this.classes.visibleVariant), this.nodes.input.setAttribute("aria-expanded", !0), (this.isResultVisible = !0), (n(this.callbacks.onOpen) && this.callbacks.onOpen(this.nodes)) || !0;
                }
            }),
            (i.prototype.close = function () {
                if (!this.isResultVisible) return !0;
                if (n(this.callbacks.onBeforeClose)) {
                    var e = this.callbacks.onBeforeClose(this.nodes);
                    if (r(e) && !e) return !1;
                }
                return (
                    this.nodes && this.nodes.result.classList.remove(this.classes.visibleVariant),
                    this.nodes.input.setAttribute("aria-expanded", !1),
                    this._clearAriaActiveDescendant(),
                    this._setKeyword(""),
                    n(this.callbacks.onClose) && this.callbacks.onClose(this.nodes),
                    (this.isResultVisible = !1),
                    (this.results = {}),
                    !0
                );
            }),
            (i.prototype.destroy = function () {
                if ((this.close(), n(this.callbacks.onBeforeDestroy))) {
                    var e = this.callbacks.onBeforeDestroy(this.nodes);
                    if (r(e) && !e) return !1;
                }
                var t;
                return (
                    this.nodes.result.classList.remove(this.classes.visibleVariant),
                    (t = this.nodes.input).removeAttribute("autocorrect", "off"),
                    t.removeAttribute("autocomplete", "off"),
                    t.removeAttribute("autocapitalize", "off"),
                    t.removeAttribute("spellcheck", "false"),
                    this._removeInputEventListeners(),
                    this._removeBodyEventListener(),
                    this._removeAccessibilityAnnouncer(),
                    this._removeClearButtonEventListener(),
                    n(this.callbacks.onDestroy) && this.callbacks.onDestroy(this.nodes),
                    !0
                );
            }),
            (i.prototype.clearAndClose = function () {
                (this.nodes.input.value = ""), this.close();
            }),
            i
        );
    })(Shopify.theme.PredictiveSearch)),
    (theme.Drawers = (function () {
        function e(e, t, i) {
            var s = { selectors: { openVariant: ".js-drawer-open-" + t, close: ".js-drawer-close" }, classes: { open: "js-drawer-open", openVariant: "js-drawer-open-" + t }, withPredictiveSearch: !1 };
            if (((this.nodes = { $parent: $("html").add("body"), $page: $("#PageContainer") }), (this.config = $.extend(s, i)), (this.position = t), (this.$drawer = $("#" + e)), !this.$drawer.length)) return !1;
            (this.drawerIsOpen = !1), this.init();
        }
        return (
            (e.prototype.init = function () {
                $(this.config.selectors.openVariant).on("click", $.proxy(this.open, this)), this.$drawer.on("click", this.config.selectors.close, $.proxy(this.close, this));
            }),
            (e.prototype.open = function (e) {
                var t = !1;
                if ((e ? e.preventDefault() : (t = !0), e && e.stopPropagation && (e.stopPropagation(), (this.$activeSource = $(e.currentTarget))), this.drawerIsOpen && !t)) return this.close();
                this.config.withPredictiveSearch || this.$drawer.prepareTransition(),
                    this.nodes.$parent.addClass(this.config.classes.open + " " + this.config.classes.openVariant),
                    (this.drawerIsOpen = !0),
                    this.config.onDrawerOpen && "function" == typeof this.config.onDrawerOpen && (t || this.config.onDrawerOpen()),
                    this.$activeSource && this.$activeSource.attr("aria-expanded") && this.$activeSource.attr("aria-expanded", "true");
                var i = { $container: this.$drawer, namespace: "drawer_focus" };
                return this.config.$elementToFocusOnOpen && (i.$elementToFocus = this.config.$elementToFocusOnOpen), slate.a11y.trapFocus(i), this.bindEvents(), this;
            }),
            (e.prototype.close = function () {
                this.drawerIsOpen &&
                    ($(document.activeElement).trigger("blur"),
                    this.config.withPredictiveSearch || this.$drawer.prepareTransition(),
                    this.nodes.$parent.removeClass(this.config.classes.open + " " + this.config.classes.openVariant),
                    this.$activeSource && this.$activeSource.attr("aria-expanded") && this.$activeSource.attr("aria-expanded", "false"),
                    (this.drawerIsOpen = !1),
                    slate.a11y.removeTrapFocus({ $container: this.$drawer, namespace: "drawer_focus" }),
                    this.unbindEvents(),
                    this.config.onDrawerClose && "function" == typeof this.config.onDrawerClose && this.config.onDrawerClose());
            }),
            (e.prototype.bindEvents = function () {
                this.nodes.$parent.on(
                    "keyup.drawer",
                    $.proxy(function (e) {
                        return 27 !== e.keyCode || (this.close(), !1);
                    }, this)
                ),
                    this.nodes.$page.on("touchmove.drawer", function () {
                        return !1;
                    }),
                    this.nodes.$page.on(
                        "click.drawer",
                        $.proxy(function () {
                            return this.close(), !1;
                        }, this)
                    );
            }),
            (e.prototype.unbindEvents = function () {
                this.nodes.$page.off(".drawer"), this.nodes.$parent.off(".drawer");
            }),
            e
        );
    })()),
    (theme.Helpers = (function () {
        var e = !1,
            t = "prevent-scrolling",
            i = window.pageYOffset;
        return {
            setTouch: function () {
                e = !0;
            },
            isTouch: function () {
                return e;
            },
            enableScrollLock: function () {
                (i = window.pageYOffset), (document.body.style.top = "-" + i + "px"), document.body.classList.add(t);
            },
            disableScrollLock: function () {
                document.body.classList.remove(t), document.body.style.removeProperty("top"), window.scrollTo(0, i);
            },
            debounce: function (e, t, i) {
                var s;
                return function () {
                    var a = this,
                        r = arguments,
                        n = i && !s;
                    clearTimeout(s),
                        (s = setTimeout(function () {
                            (s = null), i || e.apply(a, r);
                        }, t)),
                        n && e.apply(a, r);
                };
            },
        };
    })()),
    (theme.LibraryLoader = (function () {
        var e = "link",
            t = "script",
            i = "requested",
            s = "loaded",
            a = "https://cdn.shopify.com/shopifycloud/",
            r = {
                youtubeSdk: { tagId: "youtube-sdk", src: "https://www.youtube.com/iframe_api", type: t },
                plyrShopifyStyles: { tagId: "plyr-shopify-styles", src: a + "shopify-plyr/v1.0/shopify-plyr.css", type: e },
                modelViewerUiStyles: { tagId: "shopify-model-viewer-ui-styles", src: a + "model-viewer-ui/assets/v1.0/model-viewer-ui.css", type: e },
            };
        return {
            load: function (a, n) {
                var o = r[a];
                if (o && o.status !== i)
                    if (((n = n || function () {}), o.status !== s)) {
                        var c;
                        switch (((o.status = i), o.type)) {
                            case t:
                                c = (function (e, t) {
                                    var i = document.createElement("script");
                                    return (
                                        (i.src = e.src),
                                        i.addEventListener("load", function () {
                                            (e.status = s), t();
                                        }),
                                        i
                                    );
                                })(o, n);
                                break;
                            case e:
                                c = (function (e, t) {
                                    var i = document.createElement("link");
                                    return (
                                        (i.href = e.src),
                                        (i.rel = "stylesheet"),
                                        (i.type = "text/css"),
                                        i.addEventListener("load", function () {
                                            (e.status = s), t();
                                        }),
                                        i
                                    );
                                })(o, n);
                        }
                        (c.id = o.tagId), (o.element = c);
                        var l = document.getElementsByTagName(o.type)[0];
                        l.parentNode.insertBefore(c, l);
                    } else n();
            },
        };
    })()),
    (window.theme = window.theme || {}),
    (theme.Header = (function () {
        var e = {
                body: "body",
                navigation: "#AccessibleNav",
                siteNavHasDropdown: "[data-has-dropdowns]",
                siteNavChildLinks: ".site-nav__child-link",
                siteNavActiveDropdown: ".site-nav--active-dropdown",
                siteNavHasCenteredDropdown: ".site-nav--has-centered-dropdown",
                siteNavCenteredDropdown: ".site-nav__dropdown--centered",
                siteNavLinkMain: ".site-nav__link--main",
                siteNavChildLink: ".site-nav__link--last",
                siteNavDropdown: ".site-nav__dropdown",
                siteHeader: ".site-header",
            },
            t = { activeClass: "site-nav--active-dropdown", childLinkClass: "site-nav__child-link", rightDropdownClass: "site-nav__dropdown--right", leftDropdownClass: "site-nav__dropdown--left" },
            i = {};
        function s(e) {
            e.stopImmediatePropagation();
        }
        function a() {
            i.activeDropdown &&
                (i.activeDropdown.querySelector(e.siteNavLinkMain).setAttribute("aria-expanded", "false"),
                i.activeDropdown.classList.remove(t.activeClass),
                (i.activeDropdown = document.querySelector(e.siteNavActiveDropdown)),
                window.removeEventListener("keyup", o),
                document.body.removeEventListener("click", a));
        }
        function r(s) {
            s.forEach(function (s) {
                var a = s.querySelector(e.siteNavDropdown);
                a &&
                    ((function (e) {
                        var t = e.getBoundingClientRect(),
                            s = e.ownerDocument.defaultView;
                        return t.left + s.pageXOffset > Math.floor(i.siteHeader.offsetWidth) / 2;
                    })(s)
                        ? (a.classList.remove(t.leftDropdownClass), a.classList.add(t.rightDropdownClass))
                        : (a.classList.remove(t.rightDropdownClass), a.classList.add(t.leftDropdownClass)));
            });
        }
        function n() {
            document.querySelectorAll(e.siteNavHasCenteredDropdown).forEach(function (t) {
                var i = t.querySelector(e.siteNavCenteredDropdown),
                    s = t.offsetTop + 41;
                i.style.top = s + "px";
            });
        }
        function o(e) {
            27 === e.keyCode && a();
        }
        function c() {
            u();
        }
        function l(s) {
            var r = s.currentTarget;
            r.classList.contains(t.activeClass)
                ? a()
                : (function (s) {
                      s.classList.add(t.activeClass),
                          i.activeDropdown && a(),
                          (i.activeDropdown = s),
                          s.querySelector(e.siteNavLinkMain).setAttribute("aria-expanded", "true"),
                          setTimeout(function () {
                              window.addEventListener("keyup", o), document.body.addEventListener("click", a);
                          }, 250);
                  })(r);
        }
        function d() {
            setTimeout(function () {
                !document.activeElement.classList.contains(t.childLinkClass) && i.activeDropdown && a();
            });
        }
        var u = theme.Helpers.debounce(function () {
            r(document.querySelectorAll(e.siteNavHasDropdown)), n();
        }, 50);
        return {
            init: function () {
                var t;
                (t = document.querySelector(e.navigation)),
                    (i = {
                        nav: t,
                        topLevel: document.querySelectorAll(e.siteNavLinkMain),
                        parents: t.querySelectorAll(e.siteNavHasDropdown),
                        subMenuLinks: document.querySelectorAll(e.siteNavChildLinks),
                        activeDropdown: document.querySelector(e.siteNavActiveDropdown),
                        siteHeader: document.querySelector(e.siteHeader),
                        siteNavChildLink: document.querySelectorAll(e.siteNavChildLink),
                    }),
                    r(document.querySelectorAll(e.siteNavHasDropdown)),
                    n(),
                    i.parents.forEach(function (e) {
                        e.addEventListener("click", l);
                    }),
                    i.siteNavChildLink.forEach(function (e) {
                        e.addEventListener("focusout", d);
                    }),
                    i.topLevel.forEach(function (e) {
                        e.addEventListener("focus", a);
                    }),
                    i.subMenuLinks.forEach(function (e) {
                        e.addEventListener("click", s);
                    }),
                    window.addEventListener("resize", c);
            },
            unload: function () {
                i.topLevel.forEach(function (e) {
                    e.removeEventListener("focus", a);
                }),
                    i.subMenuLinks.forEach(function (e) {
                        e.removeEventListener("click", s);
                    }),
                    i.parents.forEach(function (e) {
                        e.removeEventListener("click", l);
                    }),
                    i.siteNavChildLink.forEach(function (e) {
                        e.removeEventListener("focusout", d);
                    }),
                    window.removeEventListener("resize", c),
                    window.removeEventListener("keyup", o),
                    document.body.removeEventListener("click", a);
            },
        };
    })()),
    (window.theme = window.theme || {}),
    (theme.MobileNav = (function () {
        var e,
            t,
            i,
            s = {
                mobileNavOpenIcon: "mobile-nav--open",
                mobileNavCloseIcon: "mobile-nav--close",
                navLinkWrapper: "mobile-nav__item",
                navLink: "mobile-nav__link",
                subNavLink: "mobile-nav__sublist-link",
                return: "mobile-nav__return-btn",
                subNavActive: "is-active",
                subNavClosing: "is-closing",
                navOpen: "js-menu--is-open",
                subNavShowing: "sub-nav--is-open",
                thirdNavShowing: "third-nav--is-open",
                subNavToggleBtn: "js-toggle-submenu",
            },
            a = {},
            r = 1,
            n = "(min-width: " + theme.breakpoints.medium + "px)",
            o = window.matchMedia(n);
        function c() {
            o.matches && a.$mobileNavContainer.hasClass(s.navOpen) && d();
        }
        function l() {
            a.$mobileNavToggle.hasClass(s.mobileNavCloseIcon)
                ? d()
                : (a.$siteHeader.outerHeight(),
                  a.$mobileNavContainer.prepareTransition().addClass(s.navOpen),
                  a.$siteHeader.removeClass("menu-close"),
                  a.$siteHeader.addClass("menu-open"),
                  slate.a11y.trapFocus({ $container: a.$sectionHeader, $elementToFocus: a.$mobileNavToggle, namespace: "navFocus" }),
                  a.$mobileNavToggle.addClass(s.mobileNavCloseIcon).removeClass(s.mobileNavOpenIcon).attr("aria-expanded", !0),
                  $(window).on("keyup.mobileNav", function (e) {
                      27 === e.which && d();
                  }));
        }
        function d() {
            a.$mobileNavContainer.prepareTransition().removeClass(s.navOpen),
                a.$siteHeader.addClass("menu-close"),
                a.$siteHeader.removeClass("menu-open"),
                a.$pageContainer.removeAttr("style"),
                slate.a11y.trapFocus({ $container: $("html"), $elementToFocus: $("body") }),
                a.$mobileNavContainer.one("TransitionEnd.navToggle webkitTransitionEnd.navToggle transitionend.navToggle oTransitionEnd.navToggle", function () {
                    slate.a11y.removeTrapFocus({ $container: a.$mobileNav, namespace: "navFocus" });
                }),
                a.$mobileNavToggle.addClass(s.mobileNavOpenIcon).removeClass(s.mobileNavCloseIcon).attr("aria-expanded", !1).focus(),
                $(window).off("keyup.mobileNav");
        }
        function u(n) {
            if (!e) {
                var o = $(n.currentTarget),
                    c = o.hasClass(s.return);
                (e = !0),
                    c ? ($("." + s.subNavToggleBtn + '[data-level="' + (r - 1) + '"]').removeClass(s.subNavActive), i && i.length && i.removeClass(s.subNavActive)) : o.addClass(s.subNavActive),
                    (i = o),
                    (function (i) {
                        var n = i ? $('.mobile-nav__dropdown[data-parent="' + i + '"]') : a.$mobileNav;
                        (r = n.data("level") ? n.data("level") : 1), t && t.length && t.prepareTransition().addClass(s.subNavClosing), (t = n);
                        var o = n.outerHeight(),
                            c = r > 2 ? s.thirdNavShowing : s.subNavShowing;
                        a.$mobileNavContainer.css("height", o).removeClass(s.thirdNavShowing).addClass(c), i || a.$mobileNavContainer.removeClass(s.thirdNavShowing).removeClass(s.subNavShowing);
                        var l = 1 === r ? a.$sectionHeader : n,
                            d = n.find("[data-menu-title=" + r + "]") || n;
                        a.$mobileNavContainer.one("TransitionEnd.subnavToggle webkitTransitionEnd.subnavToggle transitionend.subnavToggle oTransitionEnd.subnavToggle", function () {
                            slate.a11y.trapFocus({ $container: l, $elementToFocus: d, namespace: "subNavFocus" }), a.$mobileNavContainer.off(".subnavToggle"), (e = !1);
                        }),
                            t.removeClass(s.subNavClosing);
                    })(o.data("target"));
            }
        }
        return (
            $(document).on("shopify:section:unload", function (e) {
                "header" === e.detail.sectionId && o.removeListener(c);
            }),
            {
                init: function () {
                    (a = {
                        $pageContainer: $("#PageContainer"),
                        $siteHeader: $(".site-header"),
                        $mobileNavToggle: $(".js-mobile-nav-toggle"),
                        $mobileNavContainer: $(".mobile-nav-wrapper"),
                        $mobileNav: $("#MobileNav"),
                        $sectionHeader: $("#shopify-section-header"),
                        $subNavToggleBtn: $("." + s.subNavToggleBtn),
                    }).$mobileNavToggle.on("click", l),
                        a.$subNavToggleBtn.on("click.subNav", u),
                        o.addListener(c);
                },
                closeMobileNav: d,
            }
        );
    })(jQuery)),
    (function () {
        var e = $(".return-link");
        function t(e) {
            var t = document.createElement("a");
            return (t.ref = e), t.hostname;
        }
        document.referrer &&
            e.length &&
            window.history.length &&
            e.one("click", function (e) {
                e.preventDefault();
                var i = t(document.referrer);
                return t(window.location.href) === i && history.back(), !1;
            });
    })(),
    (theme.Slideshow = (function () {
        this.$slideshow = null;
        var e = { slideshow: "slideshow", slickActiveMobile: "slick-active-mobile", controlsHover: "slideshow__controls--hover", isPaused: "is-paused" },
            t = {
                section: ".shopify-section",
                wrapper: "#SlideshowWrapper-",
                slides: ".slideshow__slide",
                textWrapperMobile: ".slideshow__text-wrap--mobile",
                textContentMobile: ".slideshow__text-content--mobile",
                controls: ".slideshow__controls",
                pauseButton: ".slideshow__pause",
                dots: ".slick-dots",
                arrows: ".slideshow__arrows",
                arrowsMobile: ".slideshow__arrows--mobile",
                arrowLeft: ".slideshow__arrow-left",
                arrowRight: ".slideshow__arrow-right",
            };
        function i(i, a) {
            var r = (this.$slideshow = $(i));
            (this.adaptHeight = this.$slideshow.data("adapt-height")),
                (this.$wrapper = this.$slideshow.closest(t.wrapper + a)),
                (this.$section = this.$wrapper.closest(t.section)),
                (this.$controls = this.$wrapper.find(t.controls)),
                (this.$arrows = this.$section.find(t.arrows)),
                (this.$arrowsMobile = this.$section.find(t.arrowsMobile)),
                (this.$pause = this.$controls.find(t.pauseButton)),
                (this.$textWrapperMobile = this.$section.find(t.textWrapperMobile)),
                (this.autorotate = this.$slideshow.data("autorotate"));
            var n = this.$slideshow.data("speed"),
                o = this.$slideshow.data("slide-nav-a11y");
            (this.settings = {
                accessibility: !0,
                arrows: !0,
                cssEase: "cubic-bezier(.19,1,.22,1)",
                speed: 1800,
                dots: !1,
                fade: !0,
                draggable: !0,
                touchThreshold: 20,
                autoplay: this.autorotate,
                autoplaySpeed: n,
                appendDots: this.$arrows,
                customPaging: function (e, i) {
                    return '<a href="' + t.wrapper + a + '" aria-label="' + o.replace("[slide_number]", i + 1) + '" data-slide-number="' + i + '"></a>';
                },
            }),
                this.$slideshow.on(
                    "beforeChange",
                    function (t, i, s, a) {
                        var r = this.$dots.find("a"),
                            n = this.$mobileDots.find("li");
                        r.removeAttr("aria-current").eq(a).attr("aria-current", "true"), n.removeClass(e.slickActiveMobile).eq(a).addClass(e.slickActiveMobile), this.showMobileText(a);
                    }.bind(this)
                ),
                this.$slideshow.on(
                    "init",
                    function (i, a) {
                        var r = a.$slider,
                            n = a.$list;
                        (this.$dots = this.$section.find(t.dots)),
                            (this.$mobileDots = this.$dots.eq(1)),
                            n.removeAttr("aria-live"),
                            this.$wrapper.on("keyup", s.bind(this)),
                            this.$controls.on("keyup", s.bind(this)),
                            this.$textWrapperMobile.on("keyup", s.bind(this)),
                            this.$wrapper
                                .on(
                                    "focusin",
                                    function (e) {
                                        this.$wrapper.has(e.target).length && (n.attr("aria-live", "polite"), this.autorotate && r.slick("slickPause"));
                                    }.bind(this)
                                )
                                .on(
                                    "focusout",
                                    function (e) {
                                        this.$wrapper.has(e.target).length && (n.removeAttr("aria-live"), this.autorotate && (this.$pause.is(".is-paused") || r.slick("slickPlay")));
                                    }.bind(this)
                                ),
                            this.$dots &&
                                this.$dots
                                    .find("a")
                                    .each(function () {
                                        $(this).on("click keyup", function (e) {
                                            if ("keyup" !== e.type || e.which === slate.utils.keyboardKeys.ENTER) {
                                                e.preventDefault();
                                                var t = $(e.target).data("slide-number");
                                                r.attr("tabindex", -1).slick("slickGoTo", t), "keyup" === e.type && r.focus();
                                            }
                                        });
                                    })
                                    .eq(0)
                                    .attr("aria-current", "true"),
                            this.$controls
                                .on(
                                    "focusin",
                                    function () {
                                        this.$controls.addClass(e.controlsHover);
                                    }.bind(this)
                                )
                                .on(
                                    "focusout",
                                    function () {
                                        this.$controls.removeClass(e.controlsHover);
                                    }.bind(this)
                                );
                    }.bind(this)
                ),
                this.$slideshow.on(
                    "init",
                    function () {
                        this.$mobileDots.find("li:first-of-type").addClass(e.slickActiveMobile), this.showMobileText(0);
                    }.bind(this)
                ),
                this.autorotate &&
                    $(document).scroll(
                        $.debounce(
                            250,
                            function () {
                                this.$arrowsMobile.offset().top + this.$arrowsMobile.outerHeight() < window.pageYOffset ? r.slick("slickPause") : this.$pause.hasClass(e.isPaused) || r.slick("slickPlay");
                            }.bind(this)
                        )
                    ),
                this.adaptHeight && (this.setSlideshowHeight(), $(window).resize($.debounce(50, this.setSlideshowHeight.bind(this)))),
                this.$slideshow.slick(this.settings),
                function () {
                    this.$slideshow.find(t.slides).removeAttr("role").removeAttr("aria-labelledby"),
                        this.$dots
                            .removeAttr("role")
                            .find("li")
                            .removeAttr("role")
                            .removeAttr("aria-selected")
                            .each(function () {
                                var e = $(this),
                                    t = e.attr("aria-controls");
                                e.removeAttr("aria-controls").find("a").attr("aria-controls", t);
                            });
                }.bind(this)(),
                this.$arrows.find(t.arrowLeft).on("click", function () {
                    r.slick("slickPrev");
                }),
                this.$arrows.find(t.arrowRight).on("click", function () {
                    r.slick("slickNext");
                }),
                this.$pause.on("click", this.togglePause.bind(this));
        }
        function s() {
            event.keyCode === slate.utils.keyboardKeys.LEFTARROW && this.$slideshow.slick("slickPrev"), event.keyCode === slate.utils.keyboardKeys.RIGHTARROW && this.$slideshow.slick("slickNext");
        }
        return (
            (i.prototype.togglePause = function () {
                var t = "#Slideshow-" + this.$pause.data("id");
                this.$pause.hasClass(e.isPaused)
                    ? (this.$pause.removeClass(e.isPaused).attr("aria-pressed", "false"), this.autorotate && $(t).slick("slickPlay"))
                    : (this.$pause.addClass(e.isPaused).attr("aria-pressed", "true"), this.autorotate && $(t).slick("slickPause"));
            }),
            (i.prototype.setSlideshowHeight = function () {
                var e = this.$slideshow.data("min-aspect-ratio");
                this.$slideshow.height($(document).width() / e);
            }),
            (i.prototype.showMobileText = function (e) {
                var i = this.$textWrapperMobile.find(t.textContentMobile),
                    s = t.textContentMobile + "-" + e,
                    a = this.$textWrapperMobile.find(s);
                a.length || 1 !== this.$slideshow.find(t.slides).length ? this.$textWrapperMobile.show() : this.$textWrapperMobile.hide(), i.hide(), a.show();
            }),
            i
        );
    })()),
    (theme.Video = (function () {
        var e = !1,
            t = !1,
            i = !1,
            s = !1,
            a = {},
            r = [],
            n = {
                ratio: 16 / 9,
                scrollAnimationDuration: 400,
                playerVars: { iv_load_policy: 3, modestbranding: 1, autoplay: 0, controls: 0, wmode: "opaque", branding: 0, autohide: 0, rel: 0 },
                events: {
                    onReady: function (e) {
                        e.target.setPlaybackQuality("hd1080");
                        var t,
                            i,
                            s,
                            a,
                            r,
                            n,
                            l,
                            d,
                            h = g(e),
                            m = e.target.getVideoData().title;
                        p(),
                            $("#" + h.id).attr("tabindex", "-1"),
                            b(),
                            (t = h.$videoWrapper),
                            (i = m),
                            (s = t.find(c.playVideoBtn)),
                            (a = t.find(c.closeVideoBtn)),
                            (r = t.find(c.pauseVideoBtn)),
                            (n = a.find(c.fallbackText)),
                            (l = r.find(c.pauseVideoStop).find(c.fallbackText)),
                            (d = r.find(c.pauseVideoResume).find(c.fallbackText)),
                            s.each(function () {
                                var e = $(this).find(c.fallbackText);
                                e.text(e.text().replace("[video_title]", i));
                            }),
                            n.text(n.text().replace("[video_title]", i)),
                            l.text(l.text().replace("[video_title]", i)),
                            d.text(d.text().replace("[video_title]", i)),
                            "background" === h.type && (e.target.mute(), u(h.id)),
                            h.$videoWrapper.addClass(o.loaded);
                    },
                    onStateChange: function (t) {
                        var i = g(t);
                        switch (("background" !== i.status || w() || e || (t.data !== YT.PlayerState.PLAYING && t.data !== YT.PlayerState.BUFFERING) || (h(!0), (e = !0), i.$videoWrapper.removeClass(o.loading)), t.data)) {
                            case YT.PlayerState.ENDED:
                                !(function (e) {
                                    switch (e.type) {
                                        case "background":
                                            r[e.id].seekTo(0);
                                            break;
                                        case "image_with_play":
                                            v(e.id), y(e.id, !1);
                                    }
                                })(i);
                                break;
                            case YT.PlayerState.PAUSED:
                                setTimeout(function () {
                                    t.target.getPlayerState() === YT.PlayerState.PAUSED && f(i);
                                }, 200);
                        }
                    },
                },
            },
            o = {
                playing: "video-is-playing",
                paused: "video-is-paused",
                loading: "video-is-loading",
                loaded: "video-is-loaded",
                backgroundVideoWrapper: "video-background-wrapper",
                videoWithImage: "video--image_with_play",
                backgroundVideo: "video--background",
                userPaused: "is-paused",
                supportsAutoplay: "autoplay",
                supportsNoAutoplay: "no-autoplay",
                wrapperMinHeight: "video-section-wrapper--min-height",
            },
            c = {
                section: ".video-section",
                videoWrapper: ".video-section-wrapper",
                playVideoBtn: ".video-control__play",
                closeVideoBtn: ".video-control__close-wrapper",
                pauseVideoBtn: ".video__pause",
                pauseVideoStop: ".video__pause-stop",
                pauseVideoResume: ".video__pause-resume",
                fallbackText: ".icon__fallback-text",
            };
        function l(e) {
            (t || i) && e && "function" == typeof r[e].playVideo && u(e);
        }
        function d(e) {
            r[e] && "function" == typeof r[e].pauseVideo && r[e].pauseVideo();
        }
        function u(t, s) {
            var n = a[t],
                c = r[t],
                l = n.$videoWrapper;
            if (i) m(n);
            else {
                if (s || e) return l.removeClass(o.loading), m(n), void c.playVideo();
                c.playVideo();
            }
        }
        function h(t) {
            var s = t ? o.supportsAutoplay : o.supportsNoAutoplay;
            $(document.documentElement).removeClass(o.supportsAutoplay).removeClass(o.supportsNoAutoplay).addClass(s), t || (i = !0), (e = !0);
        }
        function p() {
            t || (w() && (i = !0), i && h(!1), (t = !0));
        }
        function m(e) {
            var t = e.$videoWrapper,
                i = t.find(c.pauseVideoBtn);
            t.removeClass(o.loading),
                i.hasClass(o.userPaused) && i.removeClass(o.userPaused),
                "background" !== e.status &&
                    ($("#" + e.id).attr("tabindex", "0"),
                    "image_with_play" === e.type && t.removeClass(o.paused).addClass(o.playing),
                    setTimeout(function () {
                        t.find(c.closeVideoBtn).focus();
                    }, n.scrollAnimationDuration));
        }
        function f(e) {
            var t = e.$videoWrapper;
            "image_with_play" === e.type && ("closed" === e.status ? t.removeClass(o.paused) : t.addClass(o.paused)), t.removeClass(o.playing);
        }
        function v(e) {
            var t = a[e],
                i = t.$videoWrapper,
                s = [o.paused, o.playing].join(" ");
            switch ((w() && i.removeAttr("style"), $("#" + t.id).attr("tabindex", "-1"), (t.status = "closed"), t.type)) {
                case "image_with_play":
                    r[e].stopVideo(), f(t);
                    break;
                case "background":
                    r[e].mute(),
                        (function (e) {
                            $("#" + e)
                                .removeClass(o.videoWithImage)
                                .addClass(o.backgroundVideo),
                                a[e].$videoWrapper.addClass(o.backgroundVideoWrapper),
                                (a[e].status = "background"),
                                _($("#" + e));
                        })(e);
            }
            i.removeClass(s);
        }
        function g(e) {
            var t = e.target.getIframe().id;
            return a[t];
        }
        function y(e, t) {
            var i = a[e],
                s = i.$videoWrapper.offset().top,
                r = i.$videoWrapper.find(c.playVideoBtn),
                l = 0,
                d = 0;
            w() && i.$videoWrapper.parent().toggleClass("page-width", !t),
                t
                    ? ((d = w() ? $(window).width() / n.ratio : i.$videoWrapper.width() / n.ratio),
                      (l = ($(window).height() - d) / 2),
                      i.$videoWrapper.removeClass(o.wrapperMinHeight).animate({ height: d }, 600),
                      (w() && Shopify.designMode) || $("html, body").animate({ scrollTop: s - l }, n.scrollAnimationDuration))
                    : ((d = w() ? i.$videoWrapper.data("mobile-height") : i.$videoWrapper.data("desktop-height")),
                      i.$videoWrapper.height(i.$videoWrapper.width() / n.ratio).animate({ height: d }, 600),
                      setTimeout(function () {
                          i.$videoWrapper.addClass(o.wrapperMinHeight);
                      }, 600),
                      r.focus());
        }
        function b() {
            $("." + o.backgroundVideo).each(function (e, t) {
                _($(t));
            });
        }
        function _(e) {
            if (s)
                if (w()) e.removeAttr("style");
                else {
                    var t = e.closest(c.videoWrapper),
                        i = t.width(),
                        a = e.width(),
                        r = t.data("desktop-height");
                    i / n.ratio < r
                        ? ((a = Math.ceil(r * n.ratio)),
                          e
                              .width(a)
                              .height(r)
                              .css({ left: (i - a) / 2, top: 0 }))
                        : ((r = Math.ceil(i / n.ratio)),
                          e
                              .width(i)
                              .height(r)
                              .css({ left: 0, top: (r - r) / 2 })),
                        e.prepareTransition(),
                        t.addClass(o.loaded);
                }
        }
        function w() {
            return $(window).width() < theme.breakpoints.medium;
        }
        function C() {
            $(document).on("click.videoPlayer", c.playVideoBtn, function (e) {
                !(function (e) {
                    var t = a[e];
                    switch ((t.$videoWrapper.addClass(o.loading), t.$videoWrapper.attr("style", "height: " + t.$videoWrapper.height() + "px"), (t.status = "open"), t.type)) {
                        case "image_with_play":
                            u(e, !0);
                            break;
                        case "background":
                            !(function (e) {
                                $("#" + e)
                                    .removeClass(o.backgroundVideo)
                                    .addClass(o.videoWithImage),
                                    setTimeout(function () {
                                        $("#" + e).removeAttr("style");
                                    }, 600),
                                    a[e].$videoWrapper.removeClass(o.backgroundVideoWrapper).addClass(o.playing),
                                    (a[e].status = "open");
                            })(e),
                                r[e].unMute(),
                                u(e, !0);
                    }
                    y(e, !0),
                        $(document).on("keydown.videoPlayer", function (e) {
                            var t = $(document.activeElement).data("controls");
                            e.keyCode === slate.utils.keyboardKeys.ESCAPE && t && (v(t), y(t, !1));
                        });
                })($(e.currentTarget).data("controls"));
            }),
                $(document).on("click.videoPlayer", c.closeVideoBtn, function (e) {
                    var t = $(e.currentTarget).data("controls");
                    $(e.currentTarget).blur(), v(t), y(t, !1);
                }),
                $(document).on("click.videoPlayer", c.pauseVideoBtn, function (e) {
                    !(function (e) {
                        var t = a[e].$videoWrapper.find(c.pauseVideoBtn),
                            i = t.hasClass(o.userPaused);
                        i ? (t.removeClass(o.userPaused), l(e)) : (t.addClass(o.userPaused), d(e)), t.attr("aria-pressed", !i);
                    })($(e.currentTarget).data("controls"));
                }),
                $(window).on(
                    "resize.videoPlayer",
                    $.debounce(200, function () {
                        if (s) {
                            var e,
                                t = window.innerHeight === screen.height;
                            if ((b(), w())) {
                                for (e in a) a.hasOwnProperty(e) && (a[e].$videoWrapper.hasClass(o.playing) && (t || (d(e), f(a[e]))), a[e].$videoWrapper.height($(document).width() / n.ratio));
                                h(!1);
                            } else for (e in (h(!0), a)) a[e].$videoWrapper.find("." + o.videoWithImage).length || (r[e].playVideo(), m(a[e]));
                        }
                    })
                ),
                $(window).on(
                    "scroll.videoPlayer",
                    $.debounce(50, function () {
                        if (s)
                            for (var e in a)
                                if (a.hasOwnProperty(e)) {
                                    var t = a[e].$videoWrapper;
                                    t.hasClass(o.playing) && (t.offset().top + 0.75 * t.height() < $(window).scrollTop() || t.offset().top + 0.25 * t.height() > $(window).scrollTop() + $(window).height()) && (v(e), y(e, !1));
                                }
                    })
                );
        }
        function S(e) {
            var t = $.extend({}, n, a[e]);
            (t.playerVars.controls = t.controls), (r[e] = new YT.Player(e, t));
        }
        return {
            init: function (e) {
                if (e.length) {
                    if (
                        ((a[e.attr("id")] = {
                            id: e.attr("id"),
                            videoId: e.data("id"),
                            type: e.data("type"),
                            status: "image_with_play" === e.data("type") ? "closed" : "background",
                            $video: e,
                            $videoWrapper: e.closest(c.videoWrapper),
                            $section: e.closest(c.section),
                            controls: "background" === e.data("type") ? 0 : 1,
                        }),
                        !s)
                    ) {
                        var t = document.createElement("script");
                        t.src = "https://www.youtube.com/iframe_api";
                        var i = document.getElementsByTagName("script")[0];
                        i.parentNode.insertBefore(t, i);
                    }
                    p();
                }
            },
            editorLoadVideo: function (e) {
                s && (S(e), C());
            },
            loadVideos: function () {
                for (var e in a) a.hasOwnProperty(e) && S(e);
                C(), (s = !0);
            },
            playVideo: l,
            pauseVideo: d,
            removeEvents: function () {
                $(document).off(".videoPlayer"), $(window).off(".videoPlayer");
            },
        };
    })()),
    (theme.ProductVideo = (function () {
        var e = {},
            t = { html5: "html5", youtube: "youtube" };
        function i(i) {
            i
                ? (function () {
                      for (var i in e)
                          if (e.hasOwnProperty(i)) {
                              var s = e[i];
                              if (s.nativeVideo) continue;
                              s.host === t.html5 && (s.element.setAttribute("controls", "controls"), (s.nativeVideo = !0));
                          }
                  })()
                : a(t.html5);
        }
        function s() {
            window.YT.Player && a(t.youtube);
        }
        function a(t) {
            for (var i in e)
                if (e.hasOwnProperty(i)) {
                    var s = e[i];
                    s.host === t && s.ready();
                }
        }
        return {
            init: function (a, r) {
                if (a.length) {
                    var n = a.find("iframe, video")[0],
                        o = a.data("mediaId");
                    if (n)
                        switch (
                            ((e[o] = {
                                mediaId: o,
                                sectionId: r,
                                host: (function (e) {
                                    return "VIDEO" === e.tagName ? t.html5 : "IFRAME" === e.tagName && /^(https?:\/\/)?(www\.)?(youtube\.com|youtube-nocookie\.com|youtu\.?be)\/.+$/.test(e.src) ? t.youtube : null;
                                })(n),
                                container: a,
                                element: n,
                                ready: function () {
                                    !(function (e) {
                                        if (!e.player) {
                                            var i = e.container.closest("[data-product-single-media-wrapper]"),
                                                s = i.data("enable-video-looping");
                                            switch (e.host) {
                                                case t.html5:
                                                    e.player = new Shopify.Plyr(e.element, { loop: { active: s } });
                                                    break;
                                                case t.youtube:
                                                    var a = i.data("video-id");
                                                    e.player = new YT.Player(e.element, {
                                                        videoId: a,
                                                        events: {
                                                            onStateChange: function (e) {
                                                                0 === e.data && s && e.target.seekTo(0);
                                                            },
                                                        },
                                                    });
                                            }
                                            i.on("mediaHidden xrLaunch", function () {
                                                e.player && (e.host === t.html5 && e.player.pause(), e.host === t.youtube && e.player.pauseVideo && e.player.pauseVideo());
                                            }),
                                                i.on("mediaVisible", function () {
                                                    theme.Helpers.isTouch() || (e.player && (e.host === t.html5 && e.player.play(), e.host === t.youtube && e.player.playVideo && e.player.playVideo()));
                                                });
                                        }
                                    })(this);
                                },
                            }),
                            e[o].host)
                        ) {
                            case t.html5:
                                window.Shopify.loadFeatures([{ name: "video-ui", version: "1.0", onLoad: i }]), theme.LibraryLoader.load("plyrShopifyStyles");
                                break;
                            case t.youtube:
                                theme.LibraryLoader.load("youtubeSdk", s);
                        }
                }
            },
            hosts: t,
            loadVideos: a,
            removeSectionVideos: function (t) {
                for (var i in e)
                    if (e.hasOwnProperty(i)) {
                        var s = e[i];
                        s.sectionId === t && (s.player && s.player.destroy(), delete e[i]);
                    }
            },
        };
    })()),
    (theme.ProductModel = (function () {
        var e = {},
            t = {},
            i = {};
        function s(t) {
            if (!t)
                if (window.ShopifyXR) {
                    for (var i in e)
                        if (e.hasOwnProperty(i)) {
                            var a = e[i];
                            if (a.loaded) continue;
                            var r = $("#ModelJson-" + i);
                            window.ShopifyXR.addModels(JSON.parse(r.html())), (a.loaded = !0);
                        }
                    window.ShopifyXR.setupXRElements();
                } else
                    document.addEventListener("shopify_xr_initialized", function () {
                        s();
                    });
        }
        function a(e) {
            if (!e)
                for (var i in t)
                    if (t.hasOwnProperty(i)) {
                        var s = t[i];
                        s.modelViewerUi || (s.modelViewerUi = new Shopify.ModelViewerUI(s.$element)), r(s);
                    }
        }
        function r(e) {
            var t = i[e.sectionId];
            e.$container.on("mediaVisible", function () {
                t.$element.attr("data-shopify-model3d-id", e.modelId), theme.Helpers.isTouch() || e.modelViewerUi.play();
            }),
                e.$container
                    .on("mediaHidden", function () {
                        t.$element.attr("data-shopify-model3d-id", t.defaultId), e.modelViewerUi.pause();
                    })
                    .on("xrLaunch", function () {
                        e.modelViewerUi.pause();
                    });
        }
        return {
            init: function (r, n) {
                (e[n] = { loaded: !1 }),
                    r.each(function (e) {
                        var s = $(this),
                            a = s.data("media-id"),
                            r = $(s.find("model-viewer")[0]),
                            o = r.data("model-id");
                        if (0 === e) {
                            var c = s.closest("[data-product-single-media-group]").find("[data-shopify-xr]");
                            i[n] = { $element: c, defaultId: o };
                        }
                        t[a] = { modelId: o, sectionId: n, $container: s, $element: r };
                    }),
                    window.Shopify.loadFeatures([
                        { name: "shopify-xr", version: "1.0", onLoad: s },
                        { name: "model-viewer-ui", version: "1.0", onLoad: a },
                    ]),
                    theme.LibraryLoader.load("modelViewerUiStyles");
            },
            removeSectionModels: function (i) {
                for (var s in t) t.hasOwnProperty(s) && t[s].sectionId === i && (t[s].modelViewerUi.destroy(), delete t[s]);
                delete e[i];
            },
        };
    })()),
    (window.theme = window.theme || {}),
    (theme.FormStatus = {
        init: function () {
            (this.$statusMessage = $("[data-form-status]")),
                this.$statusMessage &&
                    (this.$statusMessage.attr("tabindex", -1).focus(),
                    this.$statusMessage.on(
                        "blur",
                        function () {
                            this.$statusMessage.removeAttr("tabindex");
                        }.bind(this)
                    ));
        },
    }),
    (theme.Hero = function (e, t) {
        (this.$hero = $(e)), (this.layout = this.$hero.data("layout"));
        var i = $("#shopify-section-" + t),
            s = i.find(".hero-fixed-width__content"),
            a = i.find(".hero-fixed-width__image");
        function r() {
            var e = s.height() + 50;
            e > a.height() && a.css("min-height", e);
        }
        "fixed_width" === this.layout &&
            (i.removeClass("index-section--flush"),
            r(),
            $(window).resize(
                $.debounce(50, function () {
                    r();
                })
            ));
    }),
    (window.theme = window.theme || {}),
    (theme.SearchResultsTemplate = (function () {
        function e(e, i, s) {
            return [
                '<div class="predictive-search">',
                (function (e, t) {
                    return 0 === e.length
                        ? ""
                        : [
                              '<div class="predictive-search-title">',
                              '<h3 id="predictive-search" class="predictive-search-title__content">' + theme.strings.products + "</h3>",
                              '<span class="predictive-search-title__loading-spinner">' + (t ? '<span class= "icon-predictive-search-spinner" ></span >' : "") + "</span>",
                              "</div>",
                          ].join("");
                })(e, i),
                (function (e, i) {
                    var s = e.length;
                    return [
                        '<ul id="predictive-search-results" class="predictive-search__list" role="listbox" aria-labelledby="predictive-search">',
                        e
                            .map(function (e, i) {
                                return (function (e, i, s) {
                                    return [
                                        '<li id="search-result-' + i + '" class="predictive-search-item" role="option" data-search-result>',
                                        '<a class="predictive-search-item__link" href="' + e.url + '" tabindex="-1">',
                                        '<div class="predictive-search__column predictive-search__column--image" data-image-with-placeholder-wrapper>',
                                        (function (e) {
                                            return null === e.image
                                                ? ""
                                                : [
                                                      '<div class="placeholder-background placeholder-background--animation" data-image-placeholder aria-hidden="true"></div>',
                                                      '<img class="predictive-search-item__image lazyload" src="' + e.image.url + '" data-src="' + e.image.url + '" data-image alt="' + e.image.alt + '" />',
                                                  ].join("");
                                        })(e),
                                        "</div>",
                                        '<div class="predictive-search__column predictive-search__column--content ' + (t() ? "" : "predictive-search__column--center") + '">',
                                        '<span class="predictive-search-item__title">',
                                        '<span class="predictive-search-item__title-text">' + e.title + "</span>",
                                        "</span>" +
                                            (t()
                                                ? (function (e) {
                                                      return [
                                                          '<dl class="predictive-search-item__details price' +
                                                              (e.isOnSale ? " price--on-sale" : "") +
                                                              (e.available ? "" : " price--sold-out") +
                                                              (!e.isPriceVaries && e.isCompareVaries ? " price--compare-price-hidden" : "") +
                                                              '">',
                                                          '<div class="predictive-search-item__detail">',
                                                          (function (e) {
                                                              return theme.settings.predictiveSearchShowVendor && "" !== e.vendor
                                                                  ? ["<dt>", '<span class="visually-hidden">' + theme.strings.vendor + "</span>", "</dt>", '<dd class="predictive-search-item__vendor">' + e.vendor + "</dd>"].join("")
                                                                  : "";
                                                          })(e),
                                                          "</div>",
                                                          '<div class="predictive-search-item__detail predictive-search-item__detail--inline">' +
                                                              (function (e) {
                                                                  if (!theme.settings.predictiveSearchShowPrice) return "";
                                                                  var t =
                                                                          '<div class="price__regular">' +
                                                                          (function (e) {
                                                                              return [
                                                                                  "<dt>",
                                                                                  '<span class="visually-hidden">' + theme.strings.regularPrice + "</span>",
                                                                                  "</dt>",
                                                                                  "<dd>",
                                                                                  '<span class="predictive-search-item__price">' + (e.isPriceVaries ? theme.strings.fromLowestPrice.replace("[price]", e.price) : e.price) + "</span>",
                                                                                  "</dd>",
                                                                              ].join("");
                                                                          })(e) +
                                                                          "</div>",
                                                                      i =
                                                                          '<div class="price__sale">' +
                                                                          (function (e) {
                                                                              return [
                                                                                  "<dt>",
                                                                                  '<span class="visually-hidden">' + theme.strings.salePrice + "</span>",
                                                                                  "</dt>",
                                                                                  "<dd>",
                                                                                  '<span class="predictive-search-item__price predictive-search-item__price--sale">' +
                                                                                      (e.isPriceVaries ? theme.strings.fromLowestPrice.replace("[price]", e.price) : e.price) +
                                                                                      "</span>",
                                                                                  "</dd>",
                                                                                  '<div class="price__compare">' +
                                                                                      (function (e) {
                                                                                          return [
                                                                                              "<dt>",
                                                                                              '<span class="visually-hidden">' + theme.strings.regularPrice + "</span> ",
                                                                                              "</dt>",
                                                                                              "<dd>",
                                                                                              '<span class="predictive-search-item__price predictive-search-item__price--compare">' + e.compareAtPrice + "</span>",
                                                                                              "</dd>",
                                                                                          ].join("");
                                                                                      })(e) +
                                                                                      "</div>",
                                                                              ].join("");
                                                                          })(e) +
                                                                          "</div>";
                                                                  return '<span class="visually-hidden">, </span><div class="price__pricing-group">' + (e.isOnSale ? i : t) + "</div>";
                                                              })(e),
                                                          "</div>",
                                                          "</dl>",
                                                      ].join("");
                                                  })(e)
                                                : ""),
                                        '<span class="visually-hidden">, </span>',
                                        '<span class="visually-hidden">' +
                                            (function (e, t) {
                                                return theme.strings.number_of_results.replace("[result_number]", e).replace("[results_count]", t);
                                            })(i + 1, s) +
                                            "</span>",
                                        "</div>",
                                        "</a>",
                                        "</li>",
                                    ].join("");
                                })(
                                    (function (e) {
                                        return {
                                            url: (e.variants.length > 0 ? e.variants[0] : e).url,
                                            image: (function (e) {
                                                var t, i;
                                                return (
                                                    e.variants.length > 0 && null !== e.variants[0].image ? (i = e.variants[0].featured_image) : e.image ? (i = e.featured_image) : (t = null),
                                                    null !== t && (t = { url: theme.Images.getSizedImageUrl(i.url, "100x"), alt: i.alt }),
                                                    t
                                                );
                                            })(e),
                                            title: e.title,
                                            vendor: e.vendor || "",
                                            price: theme.Currency.formatMoney(e.price_min, theme.moneyFormat),
                                            compareAtPrice: theme.Currency.formatMoney(e.compare_at_price_min, theme.moneyFormat),
                                            available: e.available,
                                            isOnSale: (function (e) {
                                                return null !== e.compare_at_price_min && parseInt(e.compare_at_price_min, 10) > parseInt(e.price_min, 10);
                                            })(e),
                                            isPriceVaries: (function (e) {
                                                return e.price_max !== e.price_min;
                                            })(e),
                                            isCompareVaries: (function (e) {
                                                return e.compare_at_price_max !== e.compare_at_price_min;
                                            })(e),
                                        };
                                    })(e),
                                    i,
                                    s
                                );
                            })
                            .join(""),
                        '<li id="search-all" class="predictive-search-view-all" role="option" data-search-result>' +
                            (function (e) {
                                return [
                                    '<button type="submit" class="predictive-search-view-all__button" tabindex="-1">',
                                    theme.strings.searchFor +
                                        '<span class="predictive-search-view-all__query"> &ldquo;' +
                                        ((t = e), t.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;").replace(/'/g, "&#39;")) +
                                        "&rdquo;</span>",
                                    "</button>",
                                ].join("");
                                var t;
                            })(i) +
                            "</li>",
                        "</ul>",
                    ].join("");
                })(e, s),
                "</div>",
            ].join("");
        }
        function t() {
            return [theme.settings.predictiveSearchShowPrice, theme.settings.predictiveSearchShowVendor].reduce(function (e, t) {
                return e + (t ? 1 : 0);
            }, 0);
        }
        return function (t) {
            var i = t.products || [],
                s = t.isLoading,
                a = t.searchQuery || "";
            return s && 0 === i.length
                ? [
                      '<div class="predictive-search">',
                      '<div class="predictive-search-loading">',
                      '<span class="visually-hidden">' + theme.strings.loading + "</span>",
                      '<span class="predictive-search-loading__icon">',
                      '<span class="icon-predictive-search-spinner"></span>',
                      "</span>",
                      "</div>",
                      "</div>",
                  ].join("")
                : e(i, s, a);
        };
    })()),
    (window.theme = window.theme || {}),
    (window.theme = window.theme || {}),
    (theme.SearchDrawer = (function () {
        var e,
            t = "[data-predictive-search-open-drawer]";
        return {
            init: function () {
                $(t).attr("aria-controls", "SearchDrawer").attr("aria-expanded", "false").attr("aria-haspopup", "dialog"),
                    (e = new theme.Drawers("SearchDrawer", "top", {
                        onDrawerOpen: function () {
                            $("[data-predictive-search-drawer]").css({ height: $("[data-header-section]").outerHeight() }), theme.MobileNav.closeMobileNav(), theme.Helpers.enableScrollLock();
                        },
                        onDrawerClose: function () {
                            theme.SearchHeader.clearAndClose(), $(t).focus(), theme.Helpers.disableScrollLock();
                        },
                        withPredictiveSearch: !0,
                        $elementToFocusOnOpen: $("[data-predictive-search-drawer-input]"),
                    }));
            },
            close: function () {
                e.close();
            },
        };
    })()),
    (theme.Disclosure = (function () {
        var e = "disclosure-list--visible";
        function t(e) {
            (this.$container = e), (this.cache = {}), this._cacheSelectors(), this._connectOptions(), this._connectToggle(), this._onFocusOut();
        }
        return (
            (t.prototype = Object.assign({}, t.prototype, {
                _cacheSelectors: function () {
                    this.cache = {
                        $disclosureList: this.$container.find("[data-disclosure-list]"),
                        $disclosureToggle: this.$container.find("[data-disclosure-toggle]"),
                        $disclosureInput: this.$container.find("[data-disclosure-input]"),
                        $disclosureOptions: this.$container.find("[data-disclosure-option]"),
                    };
                },
                _connectToggle: function () {
                    this.cache.$disclosureToggle.on(
                        "click",
                        function (t) {
                            var i = "true" === $(t.currentTarget).attr("aria-expanded");
                            $(t.currentTarget).attr("aria-expanded", !i), this.cache.$disclosureList.toggleClass(e);
                        }.bind(this)
                    );
                },
                _connectOptions: function () {
                    this.cache.$disclosureOptions.on(
                        "click",
                        function (e) {
                            this._submitForm($(e.currentTarget).data("value"));
                        }.bind(this)
                    );
                },
                _onFocusOut: function () {
                    this.cache.$disclosureToggle.on(
                        "focusout",
                        function (e) {
                            0 === this.$container.has(e.relatedTarget).length && this._hideList();
                        }.bind(this)
                    ),
                        this.cache.$disclosureList.on(
                            "focusout",
                            function (t) {
                                var i = $(t.currentTarget).has(t.relatedTarget).length > 0;
                                this.cache.$disclosureList.hasClass(e) && !i && this._hideList();
                            }.bind(this)
                        ),
                        this.$container.on(
                            "keyup",
                            function (e) {
                                e.which === slate.utils.keyboardKeys.ESCAPE && (this._hideList(), this.cache.$disclosureToggle.focus());
                            }.bind(this)
                        ),
                        $("body").on(
                            "click",
                            function (t) {
                                var i = this.$container.has(t.target).length > 0;
                                this.cache.$disclosureList.hasClass(e) && !i && this._hideList();
                            }.bind(this)
                        );
                },
                _submitForm: function (e) {
                    this.cache.$disclosureInput.val(e), this.$container.parents("form").submit();
                },
                _hideList: function () {
                    this.cache.$disclosureList.removeClass(e), this.cache.$disclosureToggle.attr("aria-expanded", !1);
                },
                unload: function () {
                    this.cache.$disclosureOptions.off(), this.cache.$disclosureToggle.off(), this.cache.$disclosureList.off(), this.$container.off();
                },
            })),
            t
        );
    })()),
    (theme.Zoom = (function () {
        var e = "data-image-zoom-target";
        function t(e) {
            (this.container = e), (this.cache = {}), (this.url = e.dataset.zoom), this._cacheSelectors(), this.cache.sourceImage && this._duplicateImage();
        }
        return (
            (t.prototype = Object.assign({}, t.prototype, {
                _cacheSelectors: function () {
                    this.cache = { sourceImage: this.container.querySelector("[data-image-zoom]") };
                },
                _init: function () {
                    var e = this.cache.targetImage.width,
                        t = this.cache.targetImage.height;
                    this.cache.sourceImage === this.cache.targetImage ? ((this.sourceWidth = e), (this.sourceHeight = t)) : ((this.sourceWidth = this.cache.sourceImage.width), (this.sourceHeight = this.cache.sourceImage.height)),
                        (this.xRatio = (this.cache.sourceImage.width - e) / this.sourceWidth),
                        (this.yRatio = (this.cache.sourceImage.height - t) / this.sourceHeight);
                },
                _start: function (e) {
                    this._init(), this._move(e);
                },
                _stop: function () {
                    this.cache.targetImage.style.opacity = 0;
                },
                _setTopLeftMaxValues: function (e, t) {
                    return { left: Math.max(Math.min(t, this.sourceWidth), 0), top: Math.max(Math.min(e, this.sourceHeight), 0) };
                },
                _move: function (e) {
                    var t = e.pageX - (this.cache.sourceImage.getBoundingClientRect().left + window.scrollX),
                        i = e.pageY - (this.cache.sourceImage.getBoundingClientRect().top + window.scrollY),
                        s = this._setTopLeftMaxValues(i, t);
                    (i = s.top), (t = s.left), (this.cache.targetImage.style.left = -t * -this.xRatio + "px"), (this.cache.targetImage.style.top = -i * -this.yRatio + "px"), (this.cache.targetImage.style.opacity = 1);
                },
                _duplicateImage: function () {
                    this._loadImage()
                        .then(
                            function (e) {
                                (this.cache.targetImage = e),
                                    (e.style.width = e.width + "px"),
                                    (e.style.height = e.height + "px"),
                                    (e.style.position = "absolute"),
                                    (e.style.maxWidth = "none"),
                                    (e.style.maxHeight = "none"),
                                    (e.style.opacity = 0),
                                    (e.style.border = "none"),
                                    (e.style.left = 0),
                                    (e.style.top = 0),
                                    this.container.appendChild(e),
                                    this._init(),
                                    (this._start = this._start.bind(this)),
                                    (this._stop = this._stop.bind(this)),
                                    (this._move = this._move.bind(this)),
                                    this.container.addEventListener("mouseenter", this._start),
                                    this.container.addEventListener("mouseleave", this._stop),
                                    this.container.addEventListener("mousemove", this._move),
                                    (this.container.style.position = "relative"),
                                    (this.container.style.overflow = "hidden");
                            }.bind(this)
                        )
                        .catch(function (e) {
                            console.warn("Error fetching image", e);
                        });
                },
                _loadImage: function () {
                    return new Promise(
                        function (t, i) {
                            var s = new Image();
                            s.setAttribute("role", "presentation"),
                                s.setAttribute(e, !0),
                                s.classList.add("zoomImg"),
                                (s.src = this.url),
                                s.addEventListener("load", function () {
                                    t(s);
                                }),
                                s.addEventListener("error", function (e) {
                                    i(e);
                                });
                        }.bind(this)
                    );
                },
                unload: function () {
                    var t = this.container.querySelector("[" + e + "]");
                    t && t.remove(), this.container.removeEventListener("mouseenter", this._start), this.container.removeEventListener("mouseleave", this._stop), this.container.removeEventListener("mousemove", this._move);
                },
            })),
            t
        );
    })()),
    (function () {
        var e = $("#BlogTagFilter");
        e.length &&
            (slate.utils.resizeSelects(e),
            e.on("change", function () {
                location.href = $(this).val();
            }));
    })(),
    (window.theme = theme || {}),
    (theme.customerTemplates = (function () {
        var e = { RecoverHeading: "#RecoverHeading", RecoverEmail: "#RecoverEmail", LoginHeading: "#LoginHeading" };
        function t() {
            (this.$RecoverHeading = $(e.RecoverHeading)),
                (this.$RecoverEmail = $(e.RecoverEmail)),
                (this.$LoginHeading = $(e.LoginHeading)),
                $("#RecoverPassword").on(
                    "click",
                    function (e) {
                        e.preventDefault(), i(), this.$RecoverHeading.attr("tabindex", "-1").focus();
                    }.bind(this)
                ),
                $("#HideRecoverPasswordLink").on(
                    "click",
                    function (e) {
                        e.preventDefault(), $("#RecoverPasswordForm").addClass("hide"), $("#CustomerLoginForm").removeClass("hide"), this.$LoginHeading.attr("tabindex", "-1").focus();
                    }.bind(this)
                ),
                this.$RecoverHeading.on("blur", function () {
                    $(this).removeAttr("tabindex");
                }),
                this.$LoginHeading.on("blur", function () {
                    $(this).removeAttr("tabindex");
                });
        }
        function i() {
            $("#RecoverPasswordForm").removeClass("hide"), $("#CustomerLoginForm").addClass("hide"), "true" === this.$RecoverEmail.attr("aria-invalid") && this.$RecoverEmail.focus();
        }
        return {
            init: function () {
                var e, s;
                t(),
                    (function () {
                        "#recover" === window.location.hash && i.bind(this)();
                    })(),
                    $(".reset-password-success").length && $("#ResetSuccess").removeClass("hide").focus(),
                    (e = $("#AddressNewForm")),
                    (s = $("#AddressNewButton")),
                    e.length &&
                        (Shopify && new Shopify.CountryProvinceSelector("AddressCountryNew", "AddressProvinceNew", { hideElement: "AddressProvinceContainerNew" }),
                        $(".address-country-option").each(function () {
                            var e = $(this).data("form-id"),
                                t = "AddressCountry_" + e,
                                i = "AddressProvince_" + e,
                                s = "AddressProvinceContainer_" + e;
                            new Shopify.CountryProvinceSelector(t, i, { hideElement: s });
                        }),
                        $(".address-new-toggle").on("click", function () {
                            var t = "true" === s.attr("aria-expanded");
                            e.toggleClass("hide"), s.attr("aria-expanded", !t).focus();
                        }),
                        $(".address-edit-toggle").on("click", function () {
                            var e = $(this).data("form-id"),
                                t = $("#EditFormButton_" + e),
                                i = $("#EditAddress_" + e),
                                s = "true" === t.attr("aria-expanded");
                            i.toggleClass("hide"), t.attr("aria-expanded", !s).focus();
                        }),
                        $(".address-delete").on("click", function () {
                            var e = $(this),
                                t = e.data("target"),
                                i = e.data("confirm-message");
                            confirm(i || "Are you sure you wish to delete this address?") && Shopify.postLink(t, { parameters: { _method: "delete" } });
                        }));
            },
        };
    })()),
    (window.theme = window.theme || {}),
    (theme.Cart = (function () {
        var e = {
                cartCount: "[data-cart-count]",
                cartCountBubble: "[data-cart-count-bubble]",
                cartDiscount: "[data-cart-discount]",
                cartDiscountTitle: "[data-cart-discount-title]",
                cartDiscountAmount: "[data-cart-discount-amount]",
                cartDiscountWrapper: "[data-cart-discount-wrapper]",
                cartErrorMessage: "[data-cart-error-message]",
                cartErrorMessageWrapper: "[data-cart-error-message-wrapper]",
                cartItem: "[data-cart-item]",
                cartItemDetails: "[data-cart-item-details]",
                cartItemDiscount: "[data-cart-item-discount]",
                cartItemDiscountedPriceGroup: "[data-cart-item-discounted-price-group]",
                cartItemDiscountTitle: "[data-cart-item-discount-title]",
                cartItemDiscountAmount: "[data-cart-item-discount-amount]",
                cartItemDiscountList: "[data-cart-item-discount-list]",
                cartItemFinalPrice: "[data-cart-item-final-price]",
                cartItemImage: "[data-cart-item-image]",
                cartItemLinePrice: "[data-cart-item-line-price]",
                cartItemOriginalPrice: "[data-cart-item-original-price]",
                cartItemPrice: "[data-cart-item-price]",
                cartItemPriceList: "[data-cart-item-price-list]",
                cartItemProperty: "[data-cart-item-property]",
                cartItemPropertyName: "[data-cart-item-property-name]",
                cartItemPropertyValue: "[data-cart-item-property-value]",
                cartItemRegularPriceGroup: "[data-cart-item-regular-price-group]",
                cartItemRegularPrice: "[data-cart-item-regular-price]",
                cartItemTitle: "[data-cart-item-title]",
                cartItemOption: "[data-cart-item-option]",
                cartLineItems: "[data-cart-line-items]",
                cartNote: "[data-cart-notes]",
                cartQuantityErrorMessage: "[data-cart-quantity-error-message]",
                cartQuantityErrorMessageWrapper: "[data-cart-quantity-error-message-wrapper]",
                cartRemove: "[data-cart-remove]",
                cartStatus: "[data-cart-status]",
                cartSubtotal: "[data-cart-subtotal]",
                cartTableCell: "[data-cart-table-cell]",
                cartWrapper: "[data-cart-wrapper]",
                emptyPageContent: "[data-empty-page-content]",
                quantityInput: "[data-quantity-input]",
                quantityInputMobile: "[data-quantity-input-mobile]",
                quantityInputDesktop: "[data-quantity-input-desktop]",
                quantityLabelMobile: "[data-quantity-label-mobile]",
                quantityLabelDesktop: "[data-quantity-label-desktop]",
                inputQty: "[data-quantity-input]",
                thumbnails: ".cart__image",
                unitPrice: "[data-unit-price]",
                unitPriceBaseUnit: "[data-unit-price-base-unit]",
                unitPriceGroup: "[data-unit-price-group]",
            },
            t = { cartNoCookies: "cart--no-cookies", cartRemovedProduct: "cart__removed-product", hide: "hide", inputError: "input--error" },
            i = "data-cart-item-index",
            s = "data-cart-item-key",
            a = "data-cart-item-title",
            r = "data-cart-item-url",
            n = "data-quantity-item";
        (theme.breakpoints = theme.breakpoints || {}), (isNaN(theme.breakpoints.medium) || void 0 === theme.breakpoints.medium) && (theme.breakpoints.medium = 750);
        var o = "(min-width: " + theme.breakpoints.medium + "px)";
        function c(i) {
            (this.$container = $(i)),
                (this.$thumbnails = $(e.thumbnails, this.$container)),
                (this.ajaxEnabled = this.$container.data("ajax-enabled")),
                this.cookiesEnabled() || this.$container.addClass(t.cartNoCookies),
                this.$thumbnails.css("cursor", "pointer"),
                this.$container.on("click", e.thumbnails, this._handleThumbnailClick),
                this.$container.on("change", e.inputQty, $.debounce(500, this._handleInputQty.bind(this))),
                (this.mql = window.matchMedia(o)),
                this.mql.addListener(this.setQuantityFormControllers.bind(this)),
                this.setQuantityFormControllers(),
                this.ajaxEnabled && (this.$container.on("change", e.cartNote, this._onNoteChange.bind(this)), this.$container.on("click", e.cartRemove, this._onRemoveItem.bind(this)), this._setupCartTemplates());
        }
        return (
            (c.prototype = Object.assign({}, c.prototype, {
                _setupCartTemplates: function () {
                    (this.$itemTemplate = $(e.cartItem, this.$container).first().clone()),
                        (this.$itemDiscountTemplate = $(e.cartItemDiscount, this.$itemTemplate).clone()),
                        (this.$itemOptionTemplate = $(e.cartItemOption, this.$itemTemplate).clone()),
                        (this.$itemPropertyTemplate = $(e.cartItemProperty, this.$itemTemplate).clone()),
                        (this.$itemPriceListTemplate = $(e.cartItemPriceList, this.$itemTemplate).clone()),
                        (this.$itemLinePriceTemplate = $(e.cartItemLinePrice, this.$itemTemplate).clone()),
                        (this.$cartDiscountTemplate = $(e.cartDiscount, this.$container).clone());
                },
                _handleInputQty: function (t) {
                    var i = $(t.target),
                        s = i.data("quantity-item"),
                        a = i.closest(e.cartItem),
                        r = $("[data-quantity-item=" + s + "]"),
                        n = parseInt(i.val()),
                        o = !(n < 0 || isNaN(n));
                    r.val(n), this._hideCartError(), this._hideQuantityErrorMessage(), o ? o && this.ajaxEnabled && this._updateItemQuantity(s, a, r, n) : this._showQuantityErrorMessages(a);
                },
                _updateItemQuantity: function (t, a, r, n) {
                    var o = a.attr(s),
                        c = a.attr(i),
                        l = { url: "/cart/change.js", data: { quantity: n, line: c }, dataType: "json" };
                    $.post(l)
                        .done(
                            function (t) {
                                if (0 === t.item_count) this._emptyCart();
                                else if ((this._createCart(t), 0 === n)) this._showRemoveMessage(a.clone());
                                else {
                                    var i = $('[data-cart-item-key="' + o + '"]'),
                                        s = this.getItem(o, t)
                                    $(e.quantityInput, i).focus(), this._updateLiveRegion(s);
                                }
                                this._setCartCountBubble(t.item_count);
                            }.bind(this)
                        )
                        .fail(
                            function () {
                                this._showCartError(r);
                            }.bind(this)
                        );
                },
                getItem: function (e, t) {
                    return t.items.find(function (t) {
                        return t.key === e;
                    });
                },
                _liveRegionText: function (e) {
                    var t = theme.strings.update + ": [QuantityLabel]: [Quantity], [Regular] [$$] [DiscountedPrice] [$]. [PriceInformation]";
                    t = t.replace("[QuantityLabel]", theme.strings.quantity).replace("[Quantity]", e.quantity);
                    var i = "",
                        s = theme.Currency.formatMoney(e.original_line_price, theme.moneyFormat),
                        a = "",
                        r = "",
                        n = "";
                    return (
                        e.original_line_price > e.final_line_price &&
                            ((i = theme.strings.regularTotal), (a = theme.strings.discountedTotal), (r = theme.Currency.formatMoney(e.final_line_price, theme.moneyFormat)), (n = theme.strings.priceColumn)),
                        t.replace("[Regular]", i).replace("[$$]", s).replace("[DiscountedPrice]", a).replace("[$]", r).replace("[PriceInformation]", n).trim()
                    );
                },
                _updateLiveRegion: function (t) {
                    var i = $(e.cartStatus);
                    i.html(this._liveRegionText(t)).attr("aria-hidden", !1),
                        setTimeout(function () {
                            i.attr("aria-hidden", !0);
                        }, 1e3);
                },
                _createCart: function (i) {
                    var s = this._createCartDiscountList(i);
                    $(e.cartLineItems, this.$container).html(this._createLineItemList(i)),
                        this.setQuantityFormControllers(),
                        $(e.cartNote, this.$container).val(i.note),
                        0 === s.length ? $(e.cartDiscountWrapper, this.$container).html("").addClass(t.hide) : $(e.cartDiscountWrapper, this.$container).html(s).removeClass(t.hide),
                        $(e.cartSubtotal, this.$container).html(theme.Currency.formatMoney(i.total_price, theme.moneyFormatWithCurrency));
                },
                _createCartDiscountList: function (t) {
                    return $.map(
                        t.cart_level_discount_applications,
                        function (t) {
                            var i = this.$cartDiscountTemplate.clone();
                            return i.find(e.cartDiscountTitle).text(t.title), i.find(e.cartDiscountAmount).html(theme.Currency.formatMoney(t.total_allocated_amount, theme.moneyFormat)), i[0];
                        }.bind(this)
                    );
                },
                _createLineItemList: function (t) {
                    return $.map(
                        t.items,
                        function (t, i) {
                            var s = this.$itemTemplate.clone(),
                                a = this.$itemPriceListTemplate.clone();
                            this._setLineItemAttributes(s, t, i), this._setLineItemImage(s, t.featured_image), $(e.cartItemTitle, s).text(t.product_title).attr("href", t.url);
                            var r = this._createProductDetailsList(t.product_has_only_default_variant, t.options_with_values, t.properties);
                            this._setProductDetailsList(s, r),
                                this._setItemRemove(s, t.title),
                                a.html(this._createItemPrice(t.original_price, t.final_price, this.$itemPriceListTemplate)),
                                t.unit_price_measurement && a.append(this._createUnitPrice(t.unit_price, t.unit_price_measurement, this.$itemPriceListTemplate)),
                                this._setItemPrice(s, a);
                            var n = this._createItemDiscountList(t);
                            this._setItemDiscountList(s, n), this._setQuantityInputs(s, t, i);
                            var o = this._createItemPrice(t.original_line_price, t.final_line_price, this.$itemLinePriceTemplate);
                            return this._setItemLinePrice(s, o), s[0];
                        }.bind(this)
                    );
                },
                _setLineItemAttributes: function (e, t, n) {
                    e.attr(s, t.key)
                        .attr(r, t.url)
                        .attr(a, t.title)
                        .attr(i, n + 1)
                        .attr("data-cart-item-quantity", t.quantity);
                },
                _setLineItemImage: function (i, s) {
                    var a = $(e.cartItemImage, i),
                        r = null !== s.url ? theme.Images.getSizedImageUrl(s.url, "x190") : null;
                    r ? a.attr("alt", s.alt).attr("src", r).removeClass(t.hide) : a.remove();
                },
                _setProductDetailsList: function (i, s) {
                    var a = $(e.cartItemDetails, i);
                    0 === s.length ? a.addClass(t.hide).text("") : a.removeClass(t.hide).html(s);
                },
                _setItemPrice: function (t, i) {
                    $(e.cartItemPrice, t).html(i);
                },
                _setItemDiscountList: function (i, s) {
                    var a = $(e.cartItemDiscountList, i);
                    0 === s.length ? a.html("").addClass(t.hide) : a.html(s).removeClass(t.hide);
                },
                _setItemRemove: function (t, i) {
                    $(e.cartRemove, t).attr("aria-label", theme.strings.removeLabel.replace("[product]", i));
                },
                _setQuantityInputs: function (t, i, s) {
                    $(e.quantityInputMobile, t)
                        .attr("id", "updates_" + i.key)
                        .attr(n, s + 1)
                        .val(i.quantity),
                        $(e.quantityInputDesktop, t)
                            .attr("id", "updates_large_" + i.key)
                            .attr(n, s + 1)
                            .val(i.quantity),
                        $(e.quantityLabelMobile, t).attr("for", "updates_" + i.key),
                        $(e.quantityLabelDesktop, t).attr("for", "updates_large_" + i.key);
                },
                setQuantityFormControllers: function () {
                    this.mql.matches ? ($(e.quantityInputDesktop).attr("name", "updates[]"), $(e.quantityInputMobile).removeAttr("name")) : ($(e.quantityInputMobile).attr("name", "updates[]"), $(e.quantityInputDesktop).removeAttr("name"));
                },
                _setItemLinePrice: function (t, i) {
                    $(e.cartItemLinePrice, t).html(i);
                },
                _createProductDetailsList: function (e, t, i) {
                    var s = [];
                    return e || (s = s.concat(this._getOptionList(t))), null !== i && 0 !== Object.keys(i).length && (s = s.concat(this._getPropertyList(i))), s;
                },
                _getOptionList: function (e) {
                    return $.map(
                        e,
                        function (e) {
                            var i = this.$itemOptionTemplate.clone();
                            return i.text(e.name + ": " + e.value).removeClass(t.hide), i[0];
                        }.bind(this)
                    );
                },
                _getPropertyList: function (i) {
                    var s = null !== i ? Object.entries(i) : [];
                    return $.map(
                        s,
                        function (i) {
                            var s = this.$itemPropertyTemplate.clone();
                            if ("_" !== i[0].charAt(0) && 0 !== i[1].length)
                                return (
                                    s.find(e.cartItemPropertyName).text(i[0]),
                                    -1 === i[0].indexOf("/uploads/") ? s.find(e.cartItemPropertyValue).text(": " + i[1]) : s.find(e.cartItemPropertyValue).html(': <a href="' + i[1] + '"> ' + i[1].split("/").pop() + "</a>"),
                                    s.removeClass(t.hide),
                                    s[0]
                                );
                        }.bind(this)
                    );
                },
                _createItemPrice: function (i, s, a) {
                    if (i !== s) {
                        var r = $(e.cartItemDiscountedPriceGroup, a).clone();
                        return $(e.cartItemOriginalPrice, r).html(theme.Currency.formatMoney(i, theme.moneyFormat)), $(e.cartItemFinalPrice, r).html(theme.Currency.formatMoney(s, theme.moneyFormat)), r.removeClass(t.hide), r[0];
                    }
                    var n = $(e.cartItemRegularPriceGroup, a).clone();
                    return $(e.cartItemRegularPrice, n).html(theme.Currency.formatMoney(i, theme.moneyFormat)), n.removeClass(t.hide), n[0];
                },
                _createUnitPrice: function (i, s, a) {
                    var r = $(e.unitPriceGroup, a).clone(),
                        n = (1 !== s.reference_value ? s.reference_value : "") + s.reference_unit;
                    return $(e.unitPriceBaseUnit, r).text(n), $(e.unitPrice, r).html(theme.Currency.formatMoney(i, theme.moneyFormat)), r.removeClass(t.hide), r[0];
                },
                _createItemDiscountList: function (t) {
                    return $.map(
                        t.line_level_discount_allocations,
                        function (t) {
                            var i = this.$itemDiscountTemplate.clone();
                            return i.find(e.cartItemDiscountTitle).text(t.discount_application.title), i.find(e.cartItemDiscountAmount).html(theme.Currency.formatMoney(t.amount, theme.moneyFormat)), i[0];
                        }.bind(this)
                    );
                },
                _showQuantityErrorMessages: function (i) {
                    $(e.cartQuantityErrorMessage, i).text(theme.strings.quantityMinimumMessage), $(e.cartQuantityErrorMessageWrapper, i).removeClass(t.hide), $(e.inputQty, i).addClass(t.inputError).focus();
                },
                _hideQuantityErrorMessage: function () {
                    var i = $(e.cartQuantityErrorMessageWrapper).addClass(t.hide);
                    $(e.cartQuantityErrorMessage, i).text(""), $(e.inputQty, this.$container).removeClass(t.inputError);
                },
                _handleThumbnailClick: function (t) {
                    var i = $(t.target).closest(e.cartItem).data("cart-item-url");
                    window.location.href = i;
                },
                _onNoteChange: function (e) {
                    var t = e.currentTarget.value;
                    this._hideCartError(), this._hideQuantityErrorMessage();
                    var i = { url: "/cart/update.js", data: { note: t }, dataType: "json" };
                    $.post(i).fail(
                        function () {
                            this._showCartError(e.currentTarget);
                        }.bind(this)
                    );
                },
                _showCartError: function (i) {
                    $(e.cartErrorMessage).text(theme.strings.cartError), $(e.cartErrorMessageWrapper).removeClass(t.hide), i.focus();
                },
                _hideCartError: function () {
                    $(e.cartErrorMessageWrapper).addClass(t.hide), $(e.cartErrorMessage).text("");
                },
                _onRemoveItem: function (t) {
                    t.preventDefault();
                    var s = $(t.target).closest(e.cartItem),
                        a = s.attr(i);
                    this._hideCartError();
                    var r = { url: "/cart/change.js", data: { quantity: 0, line: a }, dataType: "json" };
                    $.post(r)
                        .done(
                            function (e) {
                                0 === e.item_count ? this._emptyCart() : (this._createCart(e), this._showRemoveMessage(s.clone())), this._setCartCountBubble(e.item_count);
                            }.bind(this)
                        )
                        .fail(
                            function () {
                                this._showCartError(null);
                            }.bind(this)
                        );
                },
                _showRemoveMessage: function (e) {
                    var t,
                        i = e.data("cart-item-index"),
                        s = this._getRemoveMessage(e);
                    i - 1 == 0 ? ((t = $('[data-cart-item-index="1"]', this.$container)), $(s).insertBefore(t)) : ((t = $('[data-cart-item-index="' + (i - 1) + '"]', this.$container)), s.insertAfter(t)), s.focus();
                },
                _getRemoveMessage: function (i) {
                    var s = this._formatRemoveMessage(i),
                        a = $(e.cartTableCell, i).clone();
                    return a.removeClass().addClass(t.cartRemovedProduct).attr("colspan", "4").html(s), i.attr("role", "alert").html(a).attr("tabindex", "-1"), i;
                },
                _formatRemoveMessage: function (e) {
                    var t = e.data("cart-item-quantity"),
                        i = e.attr(r),
                        s = e.attr(a);
                    return theme.strings.removedItemMessage.replace("[quantity]", t).replace("[link]", '<a href="' + i + '" class="text-link text-link--accent">' + s + "</a>");
                },
                _setCartCountBubble: function (i) {
                    (this.$cartCountBubble = this.$cartCountBubble || $(e.cartCountBubble)),
                        (this.$cartCount = this.$cartCount || $(e.cartCount)),
                        i > 0 ? (this.$cartCountBubble.removeClass(t.hide), this.$cartCount.html(i)) : (this.$cartCountBubble.addClass(t.hide), this.$cartCount.html(""));
                },
                _emptyCart: function () {
                    (this.$emptyPageContent = this.$emptyPageContent || $(e.emptyPageContent, this.$container)),
                        (this.$cartWrapper = this.$cartWrapper || $(e.cartWrapper, this.$container)),
                        this.$emptyPageContent.removeClass(t.hide),
                        this.$cartWrapper.addClass(t.hide);
                },
                cookiesEnabled: function () {
                    var e = navigator.cookieEnabled;
                    return e || ((document.cookie = "testcookie"), (e = -1 !== document.cookie.indexOf("testcookie"))), e;
                },
            })),
            c
        );
    })()),
    (window.theme = window.theme || {}),
    (theme.Filters = (function () {
        var e = { mediaQueryMediumUp: "(min-width: " + theme.breakpoints.medium + "px)" },
            t = { mainContent: "#MainContent", filterSelection: "#FilterTags", filterCatSelection: "#FilterCategory", sortSelection: "#SortBy" };
        function i(i) {
            var s = (this.$container = $(i));
            (this.$filterSelect = $(t.filterSelection, s)),
                (this.$filterCatSelect = $(t.filterCatSelection, s)),
                (this.$sortSelect = $(t.sortSelection, s)),
                (this.$selects = $(t.filterSelection, s).add($(t.filterCatSelection, s)).add($(t.sortSelection, s))),
                (this.defaultSort = this._getDefaultSortValue()),
                this.$selects.removeClass("hidden"),
                (this.initBreakpoints = this._initBreakpoints.bind(this)),
                (this.mql = window.matchMedia(e.mediaQueryMediumUp)),
                this.mql.addListener(this.initBreakpoints),
                this.$filterSelect.on("change", this._onFilterChange.bind(this)),
                this.$filterCatSelect.on("change", this._onFilterCatChange.bind(this)),
                this.$sortSelect.on("change", this._onSortChange.bind(this)),
                this._initBreakpoints(),
                this._initParams();
        }
        return (
            (i.prototype = Object.assign({}, i.prototype, {
                _initBreakpoints: function () {
                    this.mql.matches && slate.utils.resizeSelects(this.$selects);
                },
                _initParams: function () {
                    if (((self.queryParams = {}), location.search.length))
                        for (var e, t = location.search.substr(1).split("&"), i = 0; i < t.length; i++) (e = t[i].split("=")).length > 1 && (self.queryParams[decodeURIComponent(e[0])] = decodeURIComponent(e[1]));
                },
                _onSortChange: function () {
                    (self.queryParams.sort_by = this._getSortValue()), self.queryParams.page && delete self.queryParams.page, (window.location.search = decodeURIComponent($.param(self.queryParams)));
                },
                _onFilterChange: function () {
                    document.location.href = this._getFilterValue();
                },
                _getFilterValue: function () {
                    return this.$filterSelect.val();
                },
                _onFilterCatChange: function () {
                    document.location.href = this._getFilterCatValue();
                },
                _getFilterCatValue: function () {
                    return this.$filterCatSelect.val();
                },
                _getSortValue: function () {
                    return this.$sortSelect.val() || this.defaultSort;
                },
                _getDefaultSortValue: function () {
                    return this.$sortSelect.attr("data-default-sortby");
                },
                onUnload: function () {
                    this.$filterSelect.off("change", this._onFilterChange), this.$filterCatSelect.off("change", this._onFilterCatChange), this.$sortSelect.off("change", this._onSortChange), this.mql.removeListener(this.initBreakpoints);
                },
            })),
            i
        );
    })()),
    (function () {
        var e, t, i, s, a, r, n, o, c;
        for (s = (e = document.getElementsByClassName("custom-select")).length, t = 0; t < s; t++) {
            for (
                a = (r = e[t].getElementsByTagName("select")[0]).length,
                    (n = document.createElement("DIV")).setAttribute("class", "select-selected"),
                    n.innerHTML = r.options[r.selectedIndex].innerHTML,
                    e[t].appendChild(n),
                    (o = document.createElement("DIV")).setAttribute("class", "select-items select-hide"),
                    i = 0;
                i < a;
                i++
            )
                ((c = document.createElement("DIV")).innerHTML = r.options[i].innerHTML),
                    c.addEventListener("click", function (e) {
                        var t, i, s, a, r, n, o;
                        for (n = (a = this.parentNode.parentNode.getElementsByTagName("select")[0]).length, r = this.parentNode.previousSibling, i = 0; i < n; i++)
                            if (a.options[i].innerHTML == this.innerHTML) {
                                for (a.selectedIndex = i, r.innerHTML = this.innerHTML, o = (t = this.parentNode.getElementsByClassName("same-as-selected")).length, s = 0; s < o; s++) t[s].removeAttribute("class");
                                this.setAttribute("class", "same-as-selected");
                                break;
                            }
                        r.click(), $(a).trigger("change");
                    }),
                    o.appendChild(c);
            e[t].appendChild(o),
                n.addEventListener("click", function (e) {
                    e.stopPropagation(), l(this), this.nextSibling.classList.toggle("select-hide");
                });
        }
        function l(e) {
            var t,
                i,
                s,
                a,
                r,
                n = [];
            for (t = document.getElementsByClassName("select-items"), i = document.getElementsByClassName("select-selected"), a = t.length, r = i.length, s = 0; s < r; s++) e == i[s] && n.push(s);
            for (s = 0; s < a; s++) n.indexOf(s) && t[s].classList.add("select-hide");
        }
        document.addEventListener("click", l);
    })(),
    (window.theme = window.theme || {}),
    (theme.HeaderSection = (function () {
        function e() {
            theme.Header.init(), theme.MobileNav.init(), theme.SearchDrawer.init();
        }
        return (
            (e.prototype = Object.assign({}, e.prototype, {
                onUnload: function () {
                    theme.Header.unload();
                },
            })),
            e
        );
    })()),
    (function () {
        var e = $("body");
        $(window).scroll(function () {
            $(window).scrollTop() >= 10 ? e.addClass("sticky") : e.removeClass("sticky");
        });
    })(),
    (theme.Maps = (function () {
        var e = null,
            t = [],
            i = { addressNoResults: theme.strings.addressNoResults, addressQueryLimit: theme.strings.addressQueryLimit, addressError: theme.strings.addressError, authError: theme.strings.authError },
            s = { section: '[data-section-type="map"]', map: "[data-map]", mapOverlay: "[data-map-overlay]" },
            a = "map-section--load-error",
            r = "map-section__error errors text-center";
        function n(i) {
            (this.$container = $(i)),
                (this.$map = this.$container.find(s.map)),
                (this.key = this.$map.data("api-key")),
                void 0 !== this.key &&
                    ("loaded" === e
                        ? this.createMap()
                        : (t.push(this),
                          "loading" !== e &&
                              ((e = "loading"),
                              void 0 === window.google &&
                                  $.getScript("https://maps.googleapis.com/maps/api/js?key=" + this.key).then(function () {
                                      (e = "loaded"),
                                          $.each(t, function (e, t) {
                                              t.createMap();
                                          });
                                  }))));
        }
        return (
            (window.gm_authFailure = function () {
                Shopify.designMode && ($(s.section).addClass(a), $(s.map).remove(), $(s.mapOverlay).after('<div class="' + r + '">' + theme.strings.authError + "</div>"));
            }),
            (n.prototype = Object.assign({}, n.prototype, {
                createMap: function () {
                    var e = this.$map;
                    return (function (e) {
                        var t = $.Deferred(),
                            i = new google.maps.Geocoder(),
                            s = e.data("address-setting");
                        return (
                            i.geocode({ address: s }, function (e, i) {
                                i !== google.maps.GeocoderStatus.OK && t.reject(i), t.resolve(e);
                            }),
                            t
                        );
                    })(e)
                        .then(
                            function (t) {
                                var i = { zoom: 14, center: t[0].geometry.location, draggable: !1, clickableIcons: !1, scrollwheel: !1, disableDoubleClickZoom: !0, disableDefaultUI: !0 },
                                    s = (this.map = new google.maps.Map(e[0], i)),
                                    a = (this.center = s.getCenter());
                                new google.maps.Marker({ map: s, position: s.getCenter() }),
                                    google.maps.event.addDomListener(
                                        window,
                                        "resize",
                                        $.debounce(250, function () {
                                            google.maps.event.trigger(s, "resize"), s.setCenter(a), e.removeAttr("style");
                                        })
                                    );
                            }.bind(this)
                        )
                        .fail(function () {
                            var t;
                            switch (status) {
                                case "ZERO_RESULTS":
                                    t = i.addressNoResults;
                                    break;
                                case "OVER_QUERY_LIMIT":
                                    t = i.addressQueryLimit;
                                    break;
                                case "REQUEST_DENIED":
                                    t = i.authError;
                                    break;
                                default:
                                    t = i.addressError;
                            }
                            Shopify.designMode &&
                                e
                                    .parent()
                                    .addClass(a)
                                    .html('<div class="' + r + '">' + t + "</div>");
                        });
                },
                onUnload: function () {
                    0 !== this.$map.length && google.maps.event.clearListeners(this.map, "resize");
                },
            })),
            n
        );
    })()),
    (theme.Product = (function () {
        function e(e) {
            var t = (this.$container = $(e)),
                i = t.attr("data-section-id");
            (this.zoomPictures = []),
                (this.ajaxEnabled = t.data("ajax-enabled")),
                (this.settings = {
                    mediaQueryMediumUp: "screen and (min-width: 850px)",
                    mediaQuerySmall: "screen and (max-width: 849px)",
                    bpSmall: !1,
                    enableHistoryState: t.data("enable-history-state") || !1,
                    namespace: ".slideshow-" + i,
                    sectionId: i,
                    sliderActive: !1,
                    zoomEnabled: !1,
                }),
                (this.selectors = {
                    addToCart: "[data-add-to-cart]",
                    addToCartText: "[data-add-to-cart-text]",
                    cartCount: "[data-cart-count]",
                    cartCountBubble: "[data-cart-count-bubble]",
                    cartPopup: "[data-cart-popup]",
                    cartPopupCartQuantity: "[data-cart-popup-cart-quantity]",
                    cartPopupClose: "[data-cart-popup-close]",
                    cartPopupDismiss: "[data-cart-popup-dismiss]",
                    cartPopupImage: "[data-cart-popup-image]",
                    cartPopupImageWrapper: "[data-cart-popup-image-wrapper]",
                    cartPopupImagePlaceholder: "[data-cart-popup-image-placeholder]",
                    cartPopupPlaceholderSize: "[data-placeholder-size]",
                    cartPopupProductDetails: "[data-cart-popup-product-details]",
                    cartPopupQuantity: "[data-cart-popup-quantity]",
                    cartPopupQuantityLabel: "[data-cart-popup-quantity-label]",
                    cartPopupTitle: "[data-cart-popup-title]",
                    cartPopupWrapper: "[data-cart-popup-wrapper]",
                    loader: "[data-loader]",
                    loaderStatus: "[data-loader-status]",
                    quantity: "[data-quantity-input]",
                    SKU: ".variant-sku",
                    productStatus: "[data-product-status]",
                    originalSelectorId: "#ProductSelect-" + i,
                    productForm: "[data-product-form]",
                    errorMessage: "[data-error-message]",
                    errorMessageWrapper: "[data-error-message-wrapper]",
                    imageZoomWrapper: "[data-image-zoom-wrapper]",
                    productMediaWrapper: "[data-product-single-media-wrapper]",
                    productThumbImages: ".product-single__thumbnail--" + i,
                    productThumbs: ".product-single__thumbnails-" + i,
                    productThumbListItem: ".product-single__thumbnails-item",
                    productThumbsWrapper: ".thumbnails-wrapper",
                    saleLabel: ".product-price__sale-label-" + i,
                    singleOptionSelector: ".single-option-selector-" + i,
                    shopifyPaymentButton: ".shopify-payment-button",
                    productMediaTypeVideo: "[data-product-media-type-video]",
                    productMediaTypeModel: "[data-product-media-type-model]",
                    priceContainer: "[data-price]",
                    regularPrice: "[data-regular-price]",
                    salePrice: "[data-sale-price]",
                    unitPrice: "[data-unit-price]",
                    unitPriceBaseUnit: "[data-unit-price-base-unit]",
                    productPolicies: "[data-product-policies]",
                }),
                (this.classes = {
                    cartPopupWrapperHidden: "cart-popup-wrapper--hidden",
                    hidden: "hide",
                    visibilityHidden: "visibility-hidden",
                    inputError: "input--error",
                    jsZoomEnabled: "js-zoom-enabled",
                    productOnSale: "price--on-sale",
                    productUnitAvailable: "price--unit-available",
                    productUnavailable: "price--unavailable",
                    productSoldOut: "price--sold-out",
                    cartImage: "cart-popup-item__image",
                    productFormErrorMessageWrapperHidden: "product-form__error-message-wrapper--hidden",
                    activeClass: "active-thumb",
                    variantSoldOut: "product-form--variant-sold-out",
                }),
                (this.$quantityInput = $(this.selectors.quantity, t)),
                (this.$errorMessageWrapper = $(this.selectors.errorMessageWrapper, t)),
                (this.$addToCart = $(this.selectors.addToCart, t)),
                (this.$addToCartText = $(this.selectors.addToCartText, this.$addToCart)),
                (this.$shopifyPaymentButton = $(this.selectors.shopifyPaymentButton, t)),
                (this.$productPolicies = $(this.selectors.productPolicies, t)),
                (this.$loader = $(this.selectors.loader, this.$addToCart)),
                (this.$loaderStatus = $(this.selectors.loaderStatus, t)),
                (this.$imageZoomWrapper = $(this.selectors.imageZoomWrapper, t)),
                $("#ProductJson-" + i).html() &&
                    ((this.productSingleObject = JSON.parse(document.getElementById("ProductJson-" + i).innerHTML)),
                    (this.settings.zoomEnabled = this.$imageZoomWrapper.hasClass(this.classes.jsZoomEnabled)),
                    (this.initMobileBreakpoint = this._initMobileBreakpoint.bind(this)),
                    (this.initDesktopBreakpoint = this._initDesktopBreakpoint.bind(this)),
                    (this.mqlSmall = window.matchMedia(this.settings.mediaQuerySmall)),
                    this.mqlSmall.addListener(this.initMobileBreakpoint),
                    (this.mqlMediumUp = window.matchMedia(this.settings.mediaQueryMediumUp)),
                    this.mqlMediumUp.addListener(this.initDesktopBreakpoint),
                    this.initMobileBreakpoint(),
                    this.initDesktopBreakpoint(),
                    this._stringOverrides(),
                    this._initVariants(),
                    this._initMediaSwitch(),
                    this._initAddToCart(),
                    this._setActiveThumbnail(),
                    this._initProductVideo(),
                    this._initModelViewerLibraries(),
                    this._initShopifyXrLaunch());
        }
        return (
            (e.prototype = Object.assign({}, e.prototype, {
                _stringOverrides: function () {
                    (theme.productStrings = theme.productStrings || {}), $.extend(theme.strings, theme.productStrings);
                },
                _initMobileBreakpoint: function () {
                    this.mqlSmall.matches
                        ? ($(this.selectors.productThumbImages).length > 4 && this._initThumbnailSlider(),
                          this.settings.zoomEnabled &&
                              this.$imageZoomWrapper.each(
                                  function (e) {
                                      this._destroyZoom(e);
                                  }.bind(this)
                              ),
                          (this.settings.bpSmall = !0))
                        : (this.settings.sliderActive && this._destroyThumbnailSlider(), (this.settings.bpSmall = !1));
                },
                _initDesktopBreakpoint: function () {
                    this.mqlMediumUp.matches &&
                        this.settings.zoomEnabled &&
                        this.$imageZoomWrapper.each(
                            function (e, t) {
                                this._enableZoom(t, e);
                            }.bind(this)
                        );
                },
                _initVariants: function () {
                    var e = {
                        $container: this.$container,
                        enableHistoryState: this.$container.data("enable-history-state") || !1,
                        singleOptionSelector: this.selectors.singleOptionSelector,
                        originalSelectorId: this.selectors.originalSelectorId,
                        product: this.productSingleObject,
                    };
                    (this.variants = new slate.Variants(e)),
                        this.$container.on("variantChange" + this.settings.namespace, this._updateAvailability.bind(this)),
                        this.$container.on("variantImageChange" + this.settings.namespace, this._updateMedia.bind(this)),
                        this.$container.on("variantPriceChange" + this.settings.namespace, this._updatePrice.bind(this)),
                        this.$container.on("variantSKUChange" + this.settings.namespace, this._updateSKU.bind(this));
                },
                _initMediaSwitch: function () {
                    if ($(this.selectors.productThumbImages).length) {
                        var e = this;
                        $(this.selectors.productThumbImages)
                            .on("click", function (t) {
                                t.preventDefault();
                                var i = $(this).data("thumbnail-id");
                                e._switchMedia(i), e._setActiveThumbnail(i);
                            })
                            .on("keyup", e._handleMediaFocus.bind(e));
                    }
                },
                _initAddToCart: function () {
                    $(this.selectors.productForm, this.$container).on(
                        "submit",
                        function (e) {
                            if (this.$addToCart.is("[aria-disabled]")) e.preventDefault();
                            else if (this.ajaxEnabled) {
                                e.preventDefault(), (this.$previouslyFocusedElement = $(":focus"));
                                var t = this.$quantityInput.val() <= 0;
                                if (t) this._showErrorMessage(theme.strings.quantityMinimumMessage);
                                else if (t || !this.ajaxEnabled);
                                else {
                                    this._handleButtonLoadingState(!0);
                                    var i = $(this.selectors.productForm, this.$container);
                                    this._addItemToCart(i);
                                }
                            }
                        }.bind(this)
                    );
                },
                _initProductVideo: function () {
                    var e = this.settings.sectionId;
                    $(this.selectors.productMediaTypeVideo, this.$container).each(function () {
                        var t = $(this);
                        theme.ProductVideo.init(t, e);
                    });
                },
                _initModelViewerLibraries: function () {
                    var e = $(this.selectors.productMediaTypeModel, this.$container);
                    e.length < 1 || theme.ProductModel.init(e, this.settings.sectionId);
                },
                _initShopifyXrLaunch: function () {
                    var e = this;
                    $(document).on("shopify_xr_launch", function () {
                        $(e.selectors.productMediaWrapper + ":not(." + e.classes.hidden + ")", e.$container).trigger("xrLaunch");
                    });
                },
                _addItemToCart: function (e) {
                    var t = { url: "/cart/add.js", data: $(e).serialize(), dataType: "json" };
                    $.post(t)
                        .done(
                            function (e) {
                                this._hideErrorMessage(), this._setupCartPopup(e);
                            }.bind(this)
                        )
                        .fail(
                            function (e) {
                                this.$previouslyFocusedElement.focus();
                                var t = e.responseJSON ? e.responseJSON.description : theme.strings.cartError;
                                this._showErrorMessage(t), this._handleButtonLoadingState(!1);
                            }.bind(this)
                        );
                },
                _handleButtonLoadingState: function (e) {
                    e
                        ? (this.$addToCart.attr("aria-disabled", !0),
                          this.$addToCartText.addClass(this.classes.hidden),
                          this.$loader.removeClass(this.classes.hidden),
                          this.$shopifyPaymentButton.attr("disabled", !0),
                          this.$loaderStatus.attr("aria-hidden", !1))
                        : (this.$addToCart.removeAttr("aria-disabled"),
                          this.$addToCartText.removeClass(this.classes.hidden),
                          this.$loader.addClass(this.classes.hidden),
                          this.$shopifyPaymentButton.removeAttr("disabled"),
                          this.$loaderStatus.attr("aria-hidden", !0));
                },
                _showErrorMessage: function (e) {
                    $(this.selectors.errorMessage, this.$container).html(e),
                        0 !== this.$quantityInput.length && this.$quantityInput.addClass(this.classes.inputError),
                        this.$errorMessageWrapper.removeClass(this.classes.productFormErrorMessageWrapperHidden).attr("aria-hidden", !0).removeAttr("aria-hidden");
                },
                _hideErrorMessage: function () {
                    this.$errorMessageWrapper.addClass(this.classes.productFormErrorMessageWrapperHidden), 0 !== this.$quantityInput.length && this.$quantityInput.removeClass(this.classes.inputError);
                },
                _setupCartPopup: function (e) {
                    (this.$cartPopup = this.$cartPopup || $(this.selectors.cartPopup)),
                        (this.$cartPopupWrapper = this.$cartPopupWrapper || $(this.selectors.cartPopupWrapper)),
                        (this.$cartPopupTitle = this.$cartPopupTitle || $(this.selectors.cartPopupTitle)),
                        (this.$cartPopupQuantity = this.$cartPopupQuantity || $(this.selectors.cartPopupQuantity)),
                        (this.$cartPopupQuantityLabel = this.$cartPopupQuantityLabel || $(this.selectors.cartPopupQuantityLabel)),
                        (this.$cartPopupClose = this.$cartPopupClose || $(this.selectors.cartPopupClose)),
                        (this.$cartPopupDismiss = this.$cartPopupDismiss || $(this.selectors.cartPopupDismiss)),
                        (this.$cartPopupImagePlaceholder = this.$cartPopupImagePlaceholder || $(this.selectors.cartPopupImagePlaceholder)),
                        this._setupCartPopupEventListeners(),
                        this._updateCartPopupContent(e);
                },
                _updateCartPopupContent: function (e) {
                    var t = this.$quantityInput.length ? this.$quantityInput.val() : 1;
                    this.$cartPopupTitle.text(e.product_title),
                        this.$cartPopupQuantity.text(t),
                        this.$cartPopupQuantityLabel.text(theme.strings.quantityLabel.replace("[count]", t)),
                        this._setCartPopupPlaceholder(e.featured_image.url, e.featured_image.aspect_ratio),
                        this._setCartPopupImage(e.featured_image.url, e.featured_image.alt),
                        this._setCartPopupProductDetails(e.product_has_only_default_variant, e.options_with_values, e.properties),
                        $.getJSON("/cart.js").then(
                            function (e) {
                                this._setCartQuantity(e.item_count), this._setCartCountBubble(e.item_count), this._showCartPopup();
                            }.bind(this)
                        );
                },
                _setupCartPopupEventListeners: function () {
                    this.$cartPopupWrapper.on(
                        "keyup",
                        function (e) {
                            e.keyCode === slate.utils.keyboardKeys.ESCAPE && this._hideCartPopup(e);
                        }.bind(this)
                    ),
                        this.$cartPopupClose.on("click", this._hideCartPopup.bind(this)),
                        this.$cartPopupDismiss.on("click", this._hideCartPopup.bind(this)),
                        $("body").on("click", this._onBodyClick.bind(this));
                },
                _setCartPopupPlaceholder: function (e, t) {
                    if (((this.$cartPopupImageWrapper = this.$cartPopupImageWrapper || $(this.selectors.cartPopupImageWrapper)), null !== e)) {
                        var i = $(this.selectors.cartPopupPlaceholderSize),
                            s = 95 * t,
                            a = 100 / t;
                        this.$cartPopupImagePlaceholder.css("max-width", s), i.css("padding-top", a + "%");
                    } else this.$cartPopupImageWrapper.addClass(this.classes.hidden);
                },
                _setCartPopupImage: function (e, t) {
                    if (null !== e) {
                        this.$cartPopupImageWrapper.removeClass(this.classes.hidden);
                        var i = theme.Images.getSizedImageUrl(e, "200x"),
                            s = document.createElement("img");
                        (s.src = i),
                            (s.alt = t),
                            s.classList.add(this.classes.cartImage),
                            (s.dataset.cartPopupImage = ""),
                            (s.onload = function () {
                                this.$cartPopupImagePlaceholder.addClass(this.classes.hidden), this.$cartPopupImageWrapper.append(s);
                            }.bind(this));
                    }
                },
                _setCartPopupProductDetails: function (e, t, i) {
                    this.$cartPopupProductDetails = this.$cartPopupProductDetails || $(this.selectors.cartPopupProductDetails);
                    var s = "";
                    e || (s += this._getVariantOptionList(t)),
                        null !== i && 0 !== Object.keys(i).length && (s += this._getPropertyList(i)),
                        0 === s.length ? (this.$cartPopupProductDetails.html(""), this.$cartPopupProductDetails.attr("hidden", "")) : (this.$cartPopupProductDetails.html(s), this.$cartPopupProductDetails.removeAttr("hidden"));
                },
                _getVariantOptionList: function (e) {
                    var t = "";
                    return (
                        e.forEach(function (e) {
                            t = t + '<li class="product-details__item product-details__item--variant-option">' + e.name + ": " + e.value + "</li>";
                        }),
                        t
                    );
                },
                _getPropertyList: function (e) {
                    var t = "";
                    return (
                        Object.entries(e).forEach(function (e) {
                            "_" !== e[0].charAt(0) && 0 !== e[1].length && (t = t + '<li class="product-details__item product-details__item--property"><span class="product-details__property-label">' + e[0] + ": </span>" + e[1]);
                        }),
                        t
                    );
                },
                _setCartQuantity: function (e) {
                    var t;
                    (this.$cartPopupCartQuantity = this.$cartPopupCartQuantity || $(this.selectors.cartPopupCartQuantity)),
                        1 === e ? (t = theme.strings.oneCartCount) : e > 1 && (t = theme.strings.otherCartCount.replace("[count]", e)),
                        this.$cartPopupCartQuantity.text(e).attr("aria-label", t);
                },
                _setCartCountBubble: function (e) {
                    (this.$cartCountBubble = this.$cartCountBubble || $(this.selectors.cartCountBubble)),
                        (this.$cartCount = this.$cartCount || $(this.selectors.cartCount)),
                        this.$cartCountBubble.removeClass(this.classes.hidden),
                        this.$cartCount.text(e);
                },
                _showCartPopup: function () {
                    this.$cartPopupWrapper.prepareTransition().removeClass(this.classes.cartPopupWrapperHidden),
                        this._handleButtonLoadingState(!1),
                        slate.a11y.trapFocus({ $container: this.$cartPopupWrapper, $elementToFocus: this.$cartPopup, namespace: "cartPopupFocus" });
                },
                _hideCartPopup: function (e) {
                    var t = 0 === e.detail;
                    this.$cartPopupWrapper.prepareTransition().addClass(this.classes.cartPopupWrapperHidden),
                        $(this.selectors.cartPopupImage).remove(),
                        this.$cartPopupImagePlaceholder.removeClass(this.classes.hidden),
                        slate.a11y.removeTrapFocus({ $container: this.$cartPopupWrapper, namespace: "cartPopupFocus" }),
                        t && this.$previouslyFocusedElement[0].focus(),
                        this.$cartPopupWrapper.off("keyup"),
                        this.$cartPopupClose.off("click"),
                        this.$cartPopupDismiss.off("click"),
                        $("body").off("click");
                },
                _onBodyClick: function (e) {
                    var t = $(e.target);
                    t[0] === this.$cartPopupWrapper[0] || t.parents(this.selectors.cartPopup).length || this._hideCartPopup(e);
                },
                _setActiveThumbnail: function (e) {
                    void 0 === e && (e = $(this.selectors.productMediaWrapper + ":not(.hide)", this.$container).data("media-id"));
                    var t = $(this.selectors.productThumbListItem + ":not(.slick-cloned)", this.$container),
                        i = t.find(this.selectors.productThumbImages + "[data-thumbnail-id='" + e + "']");
                    if (($(this.selectors.productThumbImages).removeClass(this.classes.activeClass).removeAttr("aria-current"), i.addClass(this.classes.activeClass), i.attr("aria-current", !0), t.hasClass("slick-slide"))) {
                        var s = i.parent().data("slick-index");
                        $(this.selectors.productThumbs).slick("slickGoTo", s, !0);
                    }
                },
                _switchMedia: function (e) {
                    var t = $(this.selectors.productMediaWrapper + ":not(." + this.classes.hidden + ")", this.$container),
                        i = $(this.selectors.productMediaWrapper + "[data-media-id='" + e + "']", this.$container),
                        s = $(this.selectors.productMediaWrapper + ":not([data-media-id='" + e + "'])", this.$container);
                    t.trigger("mediaHidden"), i.removeClass(this.classes.hidden).trigger("mediaVisible"), s.addClass(this.classes.hidden);
                },
                _handleMediaFocus: function (e) {
                    if (e.keyCode === slate.utils.keyboardKeys.ENTER) {
                        var t = $(e.currentTarget).data("thumbnail-id");
                        $(this.selectors.productMediaWrapper + "[data-media-id='" + t + "']", this.$container).focus();
                    }
                },
                _initThumbnailSlider: function () {
                    var e = { slidesToShow: 3, slidesToScroll: 2, infinite: !1, prevArrow: ".thumbnails-slider__prev--" + this.settings.sectionId, nextArrow: ".thumbnails-slider__next--" + this.settings.sectionId };
                    $(this.selectors.productThumbs).slick(e),
                        $(this.selectors.productThumbsWrapper, this.$container).find(".slick-list").removeAttr("aria-live"),
                        $(this.selectors.productThumbsWrapper, this.$container).find(".slick-disabled").removeAttr("aria-disabled"),
                        (this.settings.sliderActive = !0);
                },
                _destroyThumbnailSlider: function () {
                    $(this.selectors.productThumbs).slick("unslick"), (this.settings.sliderActive = !1), $(this.selectors.productThumbsWrapper, this.$container).find('[tabindex="-1"]').removeAttr("tabindex");
                },
                _liveRegionText: function (e) {
                    var t = "[Availability] [Regular] [$$] [Sale] [$]. [UnitPrice] [$$$]";
                    if (!e) return theme.strings.unavailable;
                    var i = e.available ? "" : theme.strings.soldOut + ",";
                    t = t.replace("[Availability]", i);
                    var s = "",
                        a = theme.Currency.formatMoney(e.price, theme.moneyFormat),
                        r = "",
                        n = "",
                        o = "",
                        c = "";
                    return (
                        e.compare_at_price > e.price &&
                            ((s = theme.strings.regularPrice), (a = theme.Currency.formatMoney(e.compare_at_price, theme.moneyFormat) + ","), (r = theme.strings.sale), (n = theme.Currency.formatMoney(e.price, theme.moneyFormat))),
                        e.unit_price && ((o = theme.strings.unitPrice), (c = theme.Currency.formatMoney(e.unit_price, theme.moneyFormat) + " " + theme.strings.unitPriceSeparator + " " + this._getBaseUnit(e))),
                        t.replace("[Regular]", s).replace("[$$]", a).replace("[Sale]", r).replace("[$]", n).replace("[UnitPrice]", o).replace("[$$$]", c).trim()
                    );
                },
                _updateLiveRegion: function (e) {
                    var t = e.variant,
                        i = this.container.querySelector(this.selectors.productStatus);
                    (i.innerHTML = this._liveRegionText(t)),
                        i.setAttribute("aria-hidden", !1),
                        setTimeout(function () {
                            i.setAttribute("aria-hidden", !0);
                        }, 1e3);
                },
                _updateAddToCart: function (e) {
                    var t = e.variant;
                    t
                        ? t.available
                            ? (this.$addToCart.removeAttr("aria-disabled").attr("aria-label", theme.strings.addToCart),
                              $(this.selectors.addToCartText, this.$container).html(theme.strings.addToCart).text(),
                              $(this.selectors.productForm, this.container).removeClass(this.classes.variantSoldOut))
                            : (this.$addToCart.attr("aria-disabled", !0).attr("aria-label", theme.strings.soldOut),
                              $(this.selectors.addToCartText, this.$container).html(theme.strings.soldOut).text(),
                              $(this.selectors.productForm, this.container).addClass(this.classes.variantSoldOut))
                        : (this.$addToCart.attr("aria-disabled", !0).attr("aria-label", theme.strings.unavailable),
                          $(this.selectors.addToCartText, this.$container).html(theme.strings.unavailable).text(),
                          $(this.selectors.productForm, this.container).addClass(this.classes.variantSoldOut));
                },
                _updateAvailability: function (e) {
                    this._hideErrorMessage(), this._updateAddToCart(e), this._updateLiveRegion(e), this._updatePrice(e);
                },
                _updateMedia: function (e) {
                    var t = e.variant.featured_media.id,
                        i = this.settings.sectionId + "-" + t;
                    this._switchMedia(i), this._setActiveThumbnail(i);
                },
                _updatePrice: function (e) {
                    var t = e.variant,
                        i = $(this.selectors.priceContainer, this.$container),
                        s = $(this.selectors.regularPrice, i),
                        a = $(this.selectors.salePrice, i),
                        r = $(this.selectors.unitPrice, i),
                        n = $(this.selectors.unitPriceBaseUnit, i);
                    if (
                        (i.removeClass(this.classes.productUnavailable).removeClass(this.classes.productOnSale).removeClass(this.classes.productUnitAvailable).removeClass(this.classes.productSoldOut).removeAttr("aria-hidden"),
                        this.$productPolicies.removeClass(this.classes.visibilityHidden),
                        !t)
                    )
                        return i.addClass(this.classes.productUnavailable).attr("aria-hidden", !0), void this.$productPolicies.addClass(this.classes.visibilityHidden);
                    t.available || i.addClass(this.classes.productSoldOut),
                        t.compare_at_price > t.price
                            ? (s.html(theme.Currency.formatMoney(t.compare_at_price, theme.moneyFormat)), a.html(theme.Currency.formatMoney(t.price, theme.moneyFormat)), i.addClass(this.classes.productOnSale))
                            : s.html(theme.Currency.formatMoney(t.price, theme.moneyFormat)),
                        t.unit_price && (r.html(theme.Currency.formatMoney(t.unit_price, theme.moneyFormat)), n.html(this._getBaseUnit(t)), i.addClass(this.classes.productUnitAvailable));
                },
                _getBaseUnit: function (e) {
                    return 1 === e.unit_price_measurement.reference_value ? e.unit_price_measurement.reference_unit : e.unit_price_measurement.reference_value + e.unit_price_measurement.reference_unit;
                },
                _updateSKU: function (e) {
                    var t = e.variant;
                    $(this.selectors.SKU).html(t.sku);
                },
                _enableZoom: function (e, t) {
                    this.zoomPictures[t] = new theme.Zoom(e);
                },
                _destroyZoom: function (e) {
                    0 !== this.zoomPictures.length && this.zoomPictures[e].unload();
                },
                onUnload: function () {
                    this.$container.off(this.settings.namespace),
                        theme.ProductVideo.removeSectionVideos(this.settings.sectionId),
                        theme.ProductModel.removeSectionModels(this.settings.sectionId),
                        this.mqlSmall.removeListener(this.initMobileBreakpoint),
                        this.mqlMediumUp.removeListener(this.initDesktopBreakpoint);
                },
            })),
            e
        );
    })()),
    (theme.ProductRecommendations = function (e) {
        this.$container = $(e);
        var t = this.$container.data("baseUrl") + "?section_id=product-recommendations&product_id=" + this.$container.data("productId") + "&limit=4";
        $.get(t).then(
            function (e) {
                var t = $(e).html();
                "" !== t.trim() && this.$container.html(t);
            }.bind(this)
        );
    }),
    (theme.Quotes = (function () {
        var e = { mediaQuerySmall: "screen and (max-width: 849px)", mediaQueryMedium: "screen and (min-width: 850px) and (max-width: 1099px)", mediaQueryLarge: "screen and (min-width: 1100px)", slideCount: 0 },
            t = { accessibility: !0, arrows: !0, cssEase: "cubic-bezier(.19,1,.22,1)", speed: 1800, dots: !1, autoplay: !0, autoplaySpeed: 6e3, touchThreshold: 20, slidesToShow: 3, slidesToScroll: 3, infinite: !0 };
        function i(i) {
            var s = (this.$container = $(i)).attr("data-section-id"),
                a = (this.wrapper = ".quotes-wrapper"),
                r = (this.slider = "#Quotes-" + s);
            (this.$slider = $(r, a)),
                (this.sliderActive = !1),
                (this.mediumOptions = $.extend({}, t, { slidesToShow: 2, slidesToScroll: 2, adaptiveHeight: !0 })),
                (this.mobileOptions = $.extend({}, t, { slidesToShow: 1, slidesToScroll: 1, adaptiveHeight: !0 })),
                (e.slideCount = this.$slider.data("count")),
                e.slideCount < t.slidesToShow && ((t.slidesToShow = e.slideCount), (t.slidesToScroll = e.slideCount)),
                this.$slider.on("init", this.a11y.bind(this)),
                (this.initMobileSlider = this._initMobileSlider.bind(this)),
                (this.initMediumSlider = this._initMediumSlider.bind(this)),
                (this.initDesktopSlider = this._initDesktopSlider.bind(this)),
                (this.mqlSmall = window.matchMedia(e.mediaQuerySmall)),
                this.mqlSmall.addListener(this.initMobileSlider),
                (this.mqlMedium = window.matchMedia(e.mediaQueryMedium)),
                this.mqlMedium.addListener(this.initMediumSlider),
                (this.mqlLarge = window.matchMedia(e.mediaQueryLarge)),
                this.mqlLarge.addListener(this.initDesktopSlider),
                this.initMobileSlider(),
                this.initMediumSlider(),
                this.initDesktopSlider();
        }
        return (
            (i.prototype = Object.assign({}, i.prototype, {
                onUnload: function () {
                    this.mqlSmall.removeListener(this.initMobileSlider), this.mqlMedium.removeListener(this.initMediumSlider), this.mqlLarge.removeListener(this.initDesktopSlider), $(this.slider, this.wrapper).slick("unslick");
                },
                onBlockSelect: function (e) {
                    var t = $(".quotes-slide--" + e.detail.blockId + ":not(.slick-cloned)").data("slick-index");
                    $(this.slider, this.wrapper).slick("slickGoTo", t);
                },
                a11y: function (e, t) {
                    var i = t.$list,
                        s = $(this.wrapper, this.$container);
                    i.removeAttr("aria-live"),
                        s.on("focusin", function (e) {
                            s.has(e.target).length && i.attr("aria-live", "polite");
                        }),
                        s.on("focusout", function (e) {
                            s.has(e.target).length && i.removeAttr("aria-live");
                        });
                },
                _initMobileSlider: function () {
                    this.mqlSmall.matches && this._initSlider(this.mobileOptions);
                },
                _initMediumSlider: function () {
                    this.mqlMedium.matches && this._initSlider(this.mediumOptions);
                },
                _initDesktopSlider: function () {
                    this.mqlLarge.matches && this._initSlider(t);
                },
                _initSlider: function (e) {
                    this.sliderActive && (this.$slider.slick("unslick"), (this.sliderActive = !1)), this.$slider.slick(e), (this.sliderActive = !0);
                },
            })),
            i
        );
    })()),
    (theme.slideshows = {}),
    (theme.SlideshowSection = function (e) {
        var t = (this.$container = $(e)).attr("data-section-id"),
            i = (this.slideshow = "#Slideshow-" + t);
        theme.slideshows[i] = new theme.Slideshow(i, t);
    }),
    (theme.SlideshowSection.prototype = Object.assign({}, theme.SlideshowSection.prototype, {
        onUnload: function () {
            delete theme.slideshows[this.slideshow];
        },
        onBlockSelect: function (e) {
            var t = $(this.slideshow);
            t.data("adapt-height") && theme.slideshows[this.slideshow].setSlideshowHeight();
            var i = $(".slideshow__slide--" + e.detail.blockId + ":not(.slick-cloned)").data("slick-index");
            t.slick("slickGoTo", i).slick("slickPause");
        },
        onBlockDeselect: function () {
            $(this.slideshow).slick("slickPlay");
        },
    })),
    (theme.slideshows = {}),
    (theme.VideoSection = function (e) {
        var t = (this.$container = $(e));
        $(".video", t).each(function () {
            var e = $(this);
            theme.Video.init(e), theme.Video.editorLoadVideo(e.attr("id"));
        });
    }),
    (theme.VideoSection.prototype = Object.assign({}, theme.VideoSection.prototype, {
        onUnload: function () {
            theme.Video.removeEvents();
        },
    })),
    (theme.heros = {}),
    (theme.HeroSection = function (e) {
        var t = (this.$container = $(e)).attr("data-section-id"),
            i = "#Hero-" + t;
        theme.heros[i] = new theme.Hero(i, t);
    }),
    (window.theme = window.theme || {});
var selectors = { disclosureLocale: "[data-disclosure-locale]", disclosureCurrency: "[data-disclosure-currency]" };
function onYouTubeIframeAPIReady() {
    theme.Video.loadVideos(), theme.ProductVideo.loadVideos(theme.ProductVideo.hosts.youtube);
}
(theme.FooterSection = (function () {
    function e(e) {
        (this.$container = $(e)),
            (this.cache = {}),
            this.cacheSelectors(),
            this.cache.$localeDisclosure.length && (this.localeDisclosure = new theme.Disclosure(this.cache.$localeDisclosure)),
            this.cache.$currencyDisclosure.length && (this.currencyDisclosure = new theme.Disclosure(this.cache.$currencyDisclosure));
    }
    return (
        (e.prototype = Object.assign({}, e.prototype, {
            cacheSelectors: function () {
                this.cache = { $localeDisclosure: this.$container.find(selectors.disclosureLocale), $currencyDisclosure: this.$container.find(selectors.disclosureCurrency) };
            },
            onUnload: function () {
                this.cache.$localeDisclosure.length && this.localeDisclosure.unload(), this.cache.$currencyDisclosure.length && this.currencyDisclosure.unload();
            },
        })),
        e
    );
})()),
    $(document).ready(function () {
        var e = new theme.Sections();
        e.register("cart-template", theme.Cart),
            e.register("product", theme.Product),
            e.register("collection-template", theme.Filters),
            e.register("product-template", theme.Product),
            e.register("header-section", theme.HeaderSection),
            e.register("map", theme.Maps),
            e.register("slideshow-section", theme.SlideshowSection),
            e.register("video-section", theme.VideoSection),
            e.register("quotes", theme.Quotes),
            e.register("hero-section", theme.HeroSection),
            e.register("product-recommendations", theme.ProductRecommendations),
            e.register("footer-section", theme.FooterSection);
    }),
    (theme.init = function () {
        theme.customerTemplates.init(),
            slate.rte.wrapTable({ $tables: $(".rte table,.custom__item-inner--html table"), tableWrapperClass: "scrollable-wrapper" }),
            slate.rte.wrapIframe({
                $iframes: $('.rte iframe[src*="youtube.com/embed"],.rte iframe[src*="player.vimeo"],.custom__item-inner--html iframe[src*="youtube.com/embed"],.custom__item-inner--html iframe[src*="player.vimeo"]'),
                iframeWrapperClass: "video-wrapper",
            }),
            slate.a11y.pageLinkFocus($(window.location.hash)),
            $(".in-page-link").on("click", function (e) {
                slate.a11y.pageLinkFocus($(e.currentTarget.hash));
            }),
            $('a[href="#"]').on("click", function (e) {
                e.preventDefault();
            }),
            slate.a11y.accessibleLinks({
                messages: { newWindow: theme.strings.newWindow, external: theme.strings.external, newWindowExternal: theme.strings.newWindowExternal },
                $links: $("a[href]:not([aria-describedby], .product-single__thumbnail)"),
            }),
            theme.FormStatus.init();
        var e = "[data-image]",
            t = "[data-image-placeholder]",
            i = "[data-image-with-placeholder-wrapper]",
            s = "hide";
        $(document).on("lazyloaded", function (a) {
            var r = $(a.target);
            if (r.data("bgset")) {
                var n = r.find(".lazyloaded");
                if (n.length) {
                    var o = r.data("alt"),
                        c = n.data("src") ? n.data("src") : r.data("bg");
                    n.attr("alt", o || ""), n.attr("src", c || "");
                }
            }
            r.is(e) && r.closest(i).find(t).addClass(s);
        }),
            $(e + ".lazyloaded")
                .closest(i)
                .find(t)
                .addClass(s),
            $(document).one("touchstart", function () {
                theme.Helpers.setTouch();
            });
    }),
    $(theme.init),
    $("body").on("click", ".shopify_explorer_faq__question", function (event) {
        $(this).next(".shopify_explorer_faq__answer").slideToggle(150).toggleClass("active"), $(this).toggleClass("active");
        ga('send', 'event', 'FAQ Question', 'click', event.target.innerHTML);
    }),
    $(document).on("shopify:block:select", "#shopify-section-page-shopify_explorer_faq-template", function (e) {
        $(e.target).find(".shopify_explorer_faq__answer").slideDown(150);
    }),
    $(document).on("shopify:block:deselect", "#shopify-section-page-shopify_explorer_faq-template", function (e) {
        $(e.target).find(".shopify_explorer_faq__answer").slideUp(150);
    }),
    window.addEventListener(
        "keydown",
        function (e) {
            9 === e.keyCode && document.documentElement.classList.add("tabbing");
        },
        { once: !0 }
    ),
    $(document).ready(function () {
        $(".sh-mobile-nav-button").on("click", function (e) {
            $(this).hasClass("panel-collapsed")
                ? ($(this).parents(".has-dropdown").find(".collapsein").slideDown(), $(this).removeClass("panel-collapsed"), $(this).attr("aria-expanded", "true"))
                : ($(this).parents(".has-dropdown").find(".collapsein").slideUp(), $(this).addClass("panel-collapsed"), $(this).attr("aria-expanded", "false"));
        });
    }),
    $(".has-dropdown").hover(
      function() {
        $("html").addClass("dropdown-active");
        $(".sh-header").attr("aria-expanded", "true");
      }, function() {
        $("html").removeClass("dropdown-active");
        $(".sh-header").attr("aria-expanded", "false");
      }
    )
    $(".has-dropdown").focus(function() {
        $("html").addClass("dropdown-active");
        $(".sh-header").attr("aria-expanded", "true");
    })
    $(".has-dropdown").blur(function() {
        $("html").removeClass("dropdown-active");
        $(".sh-header").attr("aria-expanded", "false");
    }),
    $(".sh-collection-dropdown-button").click(function() {
        $(".sh-collection").toggleClass("dropdown-active");
        if($(this).attr("aria-expanded") === "true"){
          $(this).attr("aria-expanded", "false");
        } else {
          $(this).attr("aria-expanded", "true");
        }
    }),
    $(".sh-collection-dropdown-button").focus(function() {
        $(".sh-collection").addClass("dropdown-active");
        $(this).attr("aria-expanded", "true");
    }),
    $(".sh-collection-dropdown-button").blur(function() {
        $(".sh-collection").removeClass("dropdown-active");
        $(this).attr("aria-expanded", "false");
    }),
    $(".sh-article-link").hover(function () {
        $(this).parent().addClass("active");
        }, function () {
        $(this).parent().removeClass("active");
    }),
    $(".sh-article-link").focus(function() {
        $(this).parent().addClass("active");
    }),
    $(".sh-article-link").blur(function() {
        $(this).parent().removeClass("active");
    }),
    $(".sh-js-quantity .minus").on("click", function() {
		var qty = parseInt($(this).parent().find('input').val());
        var id = $(this).parent().find('input').attr('data-item');
          if(qty > 0) {
            qty--;
          }
        qty = (isNaN(qty))?1:qty;
        $(this).parent().find('input').val(qty); 
        if ($( "input[name='update']".length)){
          $( "input[name='update']" ).trigger( "click" );
        }
    }),
    $(".sh-js-quantity .plus").on("click", function() {
		var qty = parseInt($(this).parent().find('input').val());
        var id = $(this).parent().find('input').attr('data-item');
        qty++;
        qty = (isNaN(qty))?1:qty;
        if (this.classList.contains("max-2")){
            if (qty > 2){
              qty = 2;
            }
        }
        $(this).parent().find('input').val(qty);
        if ($( "input[name='update']".length)){
          $( "input[name='update']" ).trigger( "click" );
        }
	}),
    $(".sh-js-quantity input").on("input",function () {
        var max = parseInt(this.max);

        if (parseInt(this.value) > max) {
            this.value = max; 
        }
    }),
    window.matchMedia("(max-width: 849px)").matches
        ? ($(".sh-btcg-image-slider").slick({ arrows: !1, speed: 20e3, autoplay: !0, autoplaySpeed: 0, cssEase: "linear", centerMode: !0, infinite: !0, slidesToShow: 1.2, slidesToScroll: 1, pauseOnHover: !1 }),
          $(".sh-tab-content-slider").slick({ slidesToShow: 1, infinite: !0, speed: 1000, cssEase: "cubic-bezier(.19,1,.22,1)" }),
          $(".sh-homepage-bundles-slider").slick({ arrows: !0, slidesToShow: 1, infinite: !0, speed: 1000, autoplay: !0, cssEase: "cubic-bezier(.19,1,.22,1)", centerMode: !0, centerPadding: '40px', autoplaySpeed: 4e3, prevArrow: $('.prev-arrow'), nextArrow: $('.next-arrow') }),
          $(".sh-suggested-products-slider").slick({ arrows: !0, slidesToShow: 2, infinite: !0, speed: 1000, autoplay: !0, cssEase: "cubic-bezier(.19,1,.22,1)", autoplaySpeed: 4e3 }))
        : window.matchMedia("(min-width: 850px) and (max-width: 1099px)").matches
        ? ($(".sh-btcg-image-slider").slick({ arrows: !1, speed: 20e3, autoplay: !0, autoplaySpeed: 0, cssEase: "linear", centerMode: !0, infinite: !0, slidesToShow: 2.5, slidesToScroll: 1, pauseOnHover: !1 }),
          $(".sh-homepage-bundles-slider").slick({ arrows: !0, slidesToShow: 1, infinite: !0, speed: 1000, autoplay: !0, cssEase: "cubic-bezier(.19,1,.22,1)", centerMode: !0, centerPadding: '80px', autoplaySpeed: 4e3, prevArrow: $('.prev-arrow'), nextArrow: $('.next-arrow') }),
          $(".sh-tab-content-slider").slick({ slidesToShow: 2, infinite: !0, speed: 1000, cssEase: "cubic-bezier(.19,1,.22,1)" }))
        : window.matchMedia("(min-width: 1100px) and (max-width: 1419px)").matches
        ? ($(".sh-btcg-image-slider").slick({ arrows: !1, speed: 20e3, autoplay: !0, autoplaySpeed: 0, cssEase: "linear", centerMode: !0, infinite: !0, slidesToShow: 3, slidesToScroll: 1, pauseOnHover: !1 }),
          $(".sh-homepage-bundles-slider").slick({ arrows: !0, slidesToShow: 1, infinite: !0, speed: 1000, autoplay: !0, cssEase: "cubic-bezier(.19,1,.22,1)", centerMode: !0, centerPadding: '20%', autoplaySpeed: 4e3, prevArrow: $('.prev-arrow'), nextArrow: $('.next-arrow') }),
          $(".sh-tab-content-slider").slick({ slidesToShow: 3, infinite: !0, speed: 1000, cssEase: "cubic-bezier(.19,1,.22,1)" }))
        : ($(".sh-btcg-image-slider").slick({ arrows: !1, speed: 20e3, autoplay: !0, autoplaySpeed: 0, cssEase: "linear", centerMode: !0, infinite: !0, slidesToShow: 4, slidesToScroll: 1, pauseOnHover: !1 }),
          $(".sh-homepage-bundles-slider").slick({ arrows: !0, slidesToShow: 1, infinite: !0, speed: 1000, autoplay: !0, cssEase: "cubic-bezier(.19,1,.22,1)", centerMode: !0, centerPadding: '25%', autoplaySpeed: 4e3, prevArrow: $('.prev-arrow'), nextArrow: $('.next-arrow') }),
          $(".sh-tab-content-slider").slick({ slidesToShow: 3, infinite: !0, speed: 1000, cssEase: "cubic-bezier(.19,1,.22,1)" })),
    window.matchMedia("(max-width: 849px)").addListener(function (e) {
        e.matches &&
            ($(".sh-btcg-image-slider").slick("unslick"),
            $(".sh-btcg-image-slider").slick({ arrows: !1, speed: 20e3, autoplay: !0, autoplaySpeed: 0, cssEase: "linear", centerMode: !0, infinite: !0, slidesToShow: 1.2, slidesToScroll: 1, pauseOnHover: !1 }),
            $(".sh-homepage-bundles-slider").slick("unslick"),
            $(".sh-homepage-bundles-slider").slick({ arrows: !0, slidesToShow: 1, infinite: !0, speed: 1000, autoplay: !0, cssEase: "cubic-bezier(.19,1,.22,1)", centerMode: !0, centerPadding: '40px', autoplaySpeed: 4e3, prevArrow: $('.prev-arrow'), nextArrow: $('.next-arrow') }),
            $(".sh-tab-content-slider").slick("unslick"),
            $(".sh-tab-content-slider").slick({ slidesToShow: 1, infinite: !0, speed: 1000, cssEase: "cubic-bezier(.19,1,.22,1)" }),
            $(".sh-suggested-products-slider").slick({ arrows: !0, slidesToShow: 2, infinite: !0, speed: 1000, autoplay: !0, cssEase: "cubic-bezier(.19,1,.22,1)", autoplaySpeed: 4e3, centerMode: !0 }));
    }),
    window.matchMedia("(min-width: 850px) and (max-width: 1099px)").addListener(function (e) {
        e.matches &&
            ($(".sh-btcg-image-slider").slick("unslick"),
            $(".sh-btcg-image-slider").slick({ arrows: !1, speed: 20e3, autoplay: !0, autoplaySpeed: 0, cssEase: "linear", centerMode: !0, infinite: !0, slidesToShow: 2.6, slidesToScroll: 1, pauseOnHover: !1 }),
            $(".sh-homepage-bundles-slider").slick("unslick"),
            $(".sh-homepage-bundles-slider").slick({ arrows: !0, slidesToShow: 1, infinite: !0, speed: 1000, autoplay: !0, cssEase: "cubic-bezier(.19,1,.22,1)", centerMode: !0, centerPadding: '80px', autoplaySpeed: 4e3, prevArrow: $('.prev-arrow'), nextArrow: $('.next-arrow') }),
            $(".sh-tab-content-slider").slick("unslick"),
            $(".sh-tab-content-slider").slick({ slidesToShow: 2, infinite: !0, speed: 1000, cssEase: "cubic-bezier(.19,1,.22,1)" }),
            $(".sh-suggested-products-slider").slick("unslick"));
    }),
    window.matchMedia("(min-width: 1100px) and (max-width: 1419px)").addListener(function (e) {
        e.matches &&
          ($(".sh-btcg-image-slider").slick("unslick"),
            $(".sh-btcg-image-slider").slick({ arrows: !1, speed: 20e3, autoplay: !0, autoplaySpeed: 0, cssEase: "linear", centerMode: !0, infinite: !0, slidesToShow: 3, slidesToScroll: 1, pauseOnHover: !1 }),
            $(".sh-tab-content-slider").slick("unslick"),
            $(".sh-homepage-bundles-slider").slick("unslick"),
            $(".sh-homepage-bundles-slider").slick({ arrows: !0, slidesToShow: 1, infinite: !0, speed: 1000, autoplay: !0, cssEase: "cubic-bezier(.19,1,.22,1)", centerMode: !0, centerPadding: '20%', autoplaySpeed: 4e3, prevArrow: $('.prev-arrow'), nextArrow: $('.next-arrow') }),
            $(".sh-tab-content-slider").slick("unslick"),
            $(".sh-tab-content-slider").slick({ slidesToShow: 3, infinite: !0, speed: 1000, cssEase: "cubic-bezier(.19,1,.22,1)" }),
            $(".sh-suggested-products-slider").slick("unslick"));
    }),
    window.matchMedia("(min-width: 1420px)").addListener(function (e) {
        e.matches &&
            ($(".sh-btcg-image-slider").slick("unslick"),
            $(".sh-btcg-image-slider").slick({ arrows: !1, speed: 20e3, autoplay: !0, autoplaySpeed: 0, cssEase: "linear", centerMode: !0, infinite: !0, slidesToShow: 4, slidesToScroll: 1, pauseOnHover: !1 }),
            $(".sh-tab-content-slider").slick("unslick"),
            $(".sh-homepage-bundles-slider").slick("unslick"),
            $(".sh-homepage-bundles-slider").slick({ arrows: !0, slidesToShow: 1, infinite: !0, speed: 1000, autoplay: !0, cssEase: "cubic-bezier(.19,1,.22,1)", centerMode: !0, centerPadding: '25%', autoplaySpeed: 4e3, prevArrow: $('.prev-arrow'), nextArrow: $('.next-arrow') }),
            $(".sh-tab-content-slider").slick("unslick"),
            $(".sh-tab-content-slider").slick({ slidesToShow: 3, infinite: !0, speed: 1000, cssEase: "cubic-bezier(.19,1,.22,1)" }),
            $(".sh-suggested-products-slider").slick("unslick"));
    });
    
	$("body p, body h1, body h2, body h3, body h4, body h5, body h6, body span").each(function() {
      $(this).html( 
        $(this).html().replace(/Better Than Clean/gi,"Better Than Clean<sup class='sh-trademark'>™</sup>")
      );
      $(this).html( 
        $(this).html().replace(/People Health First/gi,"People Health First<sup class='sh-trademark'>™</sup>")
      );
	});
	$(function() {
       $(".sh-radio-group").click(function() {
         if ($(".discomfort-why-toggle").is(":checked")) {
           $(".discomfort-why").show();
         } else {
           $(".discomfort-why").hide();
         }
       });
     });
     $(function() {
       $(".other-store-toggle").click(function() {
         if ($(".other-store-toggle").is(":checked")) {
           $(".other-store").show();
         } else {
           $(".other-store").hide();
         }
       });
     });
	$(function(){
        $checks1 = $(".sh-interested-products input:checkbox");
        $checks1.on('change', function() {
            var string = $checks1.filter(":checked").map(function(i,v){
                return this.value;
            }).get().join(", ");
            $checked1 = $(".sh-interested-products input:checkbox:checked");
            $unchecked1 = $(".sh-interested-products input:checkbox:not(:checked)")
            $c1Length = $checked1.length;
            $('.sh-input-products').val(string);
            $('.sh-interested-products p').text($c1Length + ' items checked');
            if ($c1Length > 0) {
              $('.sh-interested-products p').addClass('option-selected');
            } else {
              $('.sh-interested-products p').removeClass('option-selected');
            }
            $checked1.attr('aria-selected', true);
            $unchecked1.attr('aria-selected', false);
        });
    });
	$(function(){
        $checks2 = $(".sh-where-shop input:checkbox");
        $checks2.on('change', function() {
            var string = $checks2.filter(":checked").map(function(i,v){
                return this.value;
            }).get().join(", ");
            $checked2 = $(".sh-where-shop input:checkbox:checked");
            $unchecked2 = $(".sh-where-shop input:checkbox:not(:checked)");
            $c2Length = $checked2.length;
            $('.sh-input-shop').val(string);
            $('.sh-where-shop p').text($c2Length + ' items checked');
            if ($c2Length > 0) {
              $('.sh-where-shop p').addClass('option-selected');
            } else {
              $('.sh-where-shop p').removeClass('option-selected');
            }
            $checked2.attr('aria-selected', true);
            $unchecked2.attr('aria-selected', false);
        });
    });
	$(function(){
        $checks3 = $(".sh-what-brands input:checkbox");
        $checks3.on('change', function() {
            var string = $checks3.filter(":checked").map(function(i,v){
                return this.value;
            }).get().join(", ");
            $checked3 = $(".sh-what-brands input:checkbox:checked");
            $unchecked3 = $(".sh-what-brands input:checkbox:not(:checked)");
            $c3Length = $checked3.length;
            $('.sh-input-brands').val(string);
            $('.sh-what-brands p').text($c3Length + ' items checked');
            if ($c3Length > 0) {
              $('.sh-what-brands p').addClass('option-selected');
            } else {
              $('.sh-what-brands p').removeClass('option-selected');
            }
            $checked3.attr('aria-selected', true);
            $unchecked3.attr('aria-selected', false);
        });
    });
    if (document.querySelector('.sh-form-custom-select-wrapper')){
        document.querySelector('.sh-form-custom-select-wrapper').addEventListener('click', function() {
        this.querySelector('.sh-form-custom-select').classList.toggle('open');
      })
        document.querySelector('.sh-form-custom-select-wrapper').addEventListener('keydown', function(event) {
          if (event.keyCode === 32) {
            event.preventDefault();
          }
          else if (event.keyCode === 13) {
            event.preventDefault();
            this.querySelector('.sh-form-custom-select').classList.toggle('open');
          }
      })
        document.querySelector('.sh-form-custom-select-wrapper').addEventListener('keyup', function(event) {
          if (event.keyCode === 32) {
            event.preventDefault();
            this.querySelector('.sh-form-custom-select').classList.toggle('open');
          }
      })
      for (const trigger of document.querySelectorAll(".sh-form-custom-multiselect__trigger")) {
        trigger.addEventListener('click', function() {
            this.parentElement.classList.toggle('open');
        })
        trigger.addEventListener('keydown', function(event) {
          if (event.keyCode === 32) {
            event.preventDefault();
          }
          else if (event.keyCode === 13) {
            event.preventDefault();
            this.parentElement.classList.toggle('open');
          }
        })
        trigger.addEventListener('keyup', function(event) {
          if (event.keyCode === 32) {
            event.preventDefault();
            this.parentElement.classList.toggle('open');
          }
        })
      }
      for (const option of document.querySelectorAll(".sh-form-custom-select-option")) {
          var onSelect = function() {
            event.preventDefault();
              if (!this.classList.contains('selected')) {
                  if(this.parentNode.querySelector('.selected')){
                   this.parentNode.querySelector('.selected').classList.remove('selected');
                   this.parentNode.querySelector('[aria-selected="true"]').setAttribute('aria-selected', false);
                  }
                  this.classList.add('selected');
                  this.setAttribute('aria-selected', true);
                  this.closest('.sh-form-custom-select').querySelector('.sh-form-custom-select__trigger p').textContent = this.textContent;
                  this.closest('.sh-form-custom-select').querySelector('.sh-form-custom-select__trigger p').classList.add('option-selected');
                  document.querySelector('.sh-input-important').value = this.textContent;
              }
          }
          option.addEventListener('click', onSelect);
          option.addEventListener('keyup', onSelect);
      }
      window.addEventListener('click', function(e) {
        const select = document.querySelector('.sh-form-custom-select')
        const multiselectwrapper = document.querySelectorAll('.sh-form-custom-multiselect-wrapper')
        if (!select.contains(e.target)) {
            select.classList.remove('open');
        }
        for (const ms of multiselectwrapper ) {
          const multiselect = ms.querySelector('.sh-form-custom-multiselect')
          const multiselectdropdown = ms.querySelector('.sh-form-custom-multiselect-options')
            if (!multiselect.contains(e.target) && !multiselectdropdown.contains(e.target)) {
              multiselect.classList.remove('open');
            }
        }
      });
    }
    $('#mc-embedded-subscribe').click(function(){
      var input = $('.combined-address').val();
      if(input){
         if (/\d/g.test(input)){
           $('#locationField .sh-custom-error').hide();
         } else {
           $('#locationField .sh-custom-error').show();
           $('html, body').scrollTop($('#locationField'));
         }	
      } 
    });
	$(function(){
		window.SLIDECART_UPDATED = function(cart) {
            var button = document.querySelector('#slidecarthq .footer .button');
            var minOrder = document.querySelector('#slidecarthq .slidecarthq-minimum-order');
            if (button) {
              if (cart.original_total_price == 599 || cart.original_total_price == 799 || cart.original_total_price == 1099) {
                  if (minOrder) { 
                    minOrder.style.display = 'block'; 
                  }
                  button.setAttribute('disabled','true');
                  button.classList.add('disabled');
                  button.innerHTML='Add at least 1 more item to check out';
              } else {
                if (button.classList.contains('disabled')){
                    minOrder.style.display = 'none';
                    button.removeAttribute('disabled');
                    button.classList.remove('disabled');
                    button.innerHTML='Checkout';
                }  
              }
            }
        }
    });
$(function(){
  $(".sh-product-yotpo-stars").click(function() {
    var tabs = document.querySelectorAll('.js-tabs__title');
      if (tabs) {
          var tabReviews = tabs[tabs.length - 1];
          tabReviews.click();
          $('html, body').animate({
          scrollTop: $("#tabs").offset().top - 70
          }, 300);
      }
  });
});
$(function(){
  $(".sh-open-reamaze").click(function(){
    Reamaze.popup();
  });
});


// $(function(){
//   $('.sh-opt-in-checkbox input').click(function() {
//     if ($(this).is(':checked')) {
//       $('.sh-btn-required').removeAttr('disabled', 'false');
//       $('.sh-btn-required').attr('aria-disabled', 'false');
//       $('.sh-btn-required').removeClass('disabled');
//     } else {
//       $('.sh-btn-required').attr('disabled', 'true');
//       $('.sh-btn-required').attr('aria-disabled', 'true');
//       $('.sh-btn-required').addClass('disabled');
//     }
//   });
// });


$('a[href*="#"]')
  .not('[href="#"]')
  .not('[href="#0"]')
  .click(function(event) {
    if (
      location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') 
      && 
      location.hostname == this.hostname
    ) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      if (target.length) {
        event.preventDefault();
        $('html, body').animate({
          scrollTop: target.offset().top - 70
        }, 500, function() {
          var $target = $(target);
          $target.focus();
          if ($target.is(":focus")) {
            return false;
          } else {
            $target.attr('tabindex','-1');
            $target.focus();
          };
        });
      }
    }
  });

  $(function(){
        $('.sh-product-review-accordion-trigger').click(function() {
          if ($('.sh-product-reviews-wrapper').hasClass('open')){
            return;
        } else {
            $('.sh-product-reviews-wrapper').addClass('open');                                            
            $('.sh-product-review-accordion-trigger').attr('aria-expanded','true');
          }
        });
		$('.sh-product-review-accordion-trigger').keydown(function(event) {
          if (event.keyCode === 32) {
            event.preventDefault();
          }
          else if (event.keyCode === 13) {
            event.preventDefault();
            if ($('.sh-product-reviews-wrapper').hasClass('open')){
                return;
            } else {
                $('.sh-product-reviews-wrapper').addClass('open');                                            
                $('.sh-product-review-accordion-trigger').attr('aria-expanded','true');                                          
              }
          }
        });
        $('.sh-product-review-accordion-trigger').keyup(function(event) {
          if (event.keyCode === 32) {
            event.preventDefault();
            if ($('.sh-product-reviews-wrapper').hasClass('open')){
                return;
            } else {
                $('.sh-product-reviews-wrapper').addClass('open');                                            
                $('.sh-product-review-accordion-trigger').attr('aria-expanded','true');                                          
              }
          }
        });
        $('.js-fragrance-modal-link, .sh-product-fragrance-information-card-close').click(function() {
          if ($('.sh-product-fragrance-information-wrapper').hasClass('active')){
            $('.sh-product-fragrance-information-wrapper').removeClass('active');
        } else {
            $('.sh-product-fragrance-information-wrapper').addClass('active');                                            
          }
        });
  });